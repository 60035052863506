import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { BLACK, DARKGREY } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import MultiCheckboxComponent from "./multiple-checkbox-component";
import HomeApi from "services/HomeAPi";

const CapacityDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        background: "white",
    }

}))
function CapacityDialog(props: any) {
    const { boxView } = CapacityDialogStyle();
    const [controllerOfAccount, setControllerOfAccount] = React.useState("");
    const [capacityPermissionList, setCapacityPermissionList] = React.useState([])
    const [capacityPermissionFocusAddedList, setCapacityPermissionFocusAddedList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [permissionFirstColumnList, setPermissionFirstColumnList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [permissionSecondColumnList, setPermissionFirstSecondList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [permissionThirdColumnList, setPermissionThirdColumnList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [selectedPermission, setSelectedPermission] = React.useState([]);
    const [capacityPermissionNameList, setCapacityPermissionNameList] = React.useState([])
    const handleChange = (event: SelectChangeEvent) => {
        setControllerOfAccount(event.target.value);
    };
    const [focusArea, setFocusArea] = React.useState([
        { focus: "Activate apps", isAdded: false },
        { focus: "Add appointment", isAdded: false },
        { focus: "Add calendar", isAdded: false },
        { focus: "Add contacts", isAdded: false },
        { focus: "Add emails", isAdded: false },
        { focus: "Add funnels", isAdded: false },
        { focus: "Add pipelines", isAdded: false },
        { focus: "Add replacements", isAdded: false },
        { focus: "Add sms", isAdded: false },
        { focus: "Add tags", isAdded: false },
        { focus: "Answer chat", isAdded: false },
        { focus: "Assign product terms", isAdded: false },
        { focus: "Assign shop coupon terms", isAdded: false },
        { focus: "Assign shop order terms", isAdded: false },
        { focus: "Assign shop webhook terms", isAdded: false },
        { focus: "Assign topic tags", isAdded: false },
        { focus: "Analytics manage settings", isAdded: false },
        { focus: "Analytics view analytics", isAdded: false },
        { focus: "Cancel broadcasts", isAdded: false },
        { focus: "Cancel events", isAdded: false },
        { focus: "Create posts", isAdded: false },
        { focus: "Create sites", isAdded: false },
        { focus: "Create users", isAdded: false },
        { focus: "Delete appointment", isAdded: false },
        { focus: "Delete assignments", isAdded: false },
        { focus: " Delete calendar", isAdded: false },
        { focus: "Delete accounting", isAdded: false },
        { focus: "Delete contacts", isAdded: false },
        { focus: "Delete emails", isAdded: false },
        { focus: "Delete forums", isAdded: false },
        { focus: "Delete funnels", isAdded: false },
        { focus: "Delete item", isAdded: false },
        { focus: "Delete items", isAdded: false },
        { focus: "Delete membership plan", isAdded: false },
        { focus: "Delete newsletters", isAdded: false },
        { focus: "Delete item", isAdded: false },
        { focus: "Delete item", isAdded: false },
    ]);

    useEffect(() => {
        getCapacityPermissionList()
    }, [])
    const getCapacityPermissionList = async () => {
        const response = await HomeApi.getPermissionList();
        if (response.status === true) {
            const permissionList = response.data
            setCapacityPermissionList(response.data)
            let tempArray: any = [];
            let tempObjectArray: any = [];
            response.data.map((data: any) => {
            tempArray.push(data.name)
            tempObjectArray.push({
                id: data.id,
                focus:data.name,
                isAdded: false
            })
            });

            let tempArrayFirst: any = [];
            let tempArraySecond: any = [];
            let tempArrayThird: any = [];

            for (let i = 0; i < permissionList.length; i++) {
                if (i < 10) {
                    tempArrayFirst.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                } else if (i >= 10 && i < 20) {
                    tempArraySecond.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                } else {
                    tempArrayThird.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                }
              }
              setPermissionFirstColumnList(tempArrayFirst)
              setPermissionFirstSecondList(tempArraySecond)
              setPermissionThirdColumnList(tempArrayThird)
            setCapacityPermissionFocusAddedList(tempObjectArray)
            setCapacityPermissionNameList(tempArray)
        }
    }

    const handleOnChangeFirstColumn = (event: any, option: any, index: any) => {
        // const values = [...capacityPermissionFocusAddedList];
        // values[index].isAdded = event.target.checked;
        // setCapacityPermissionFocusAddedList(values)

        const values = [...permissionFirstColumnList];
        values[index].isAdded = event.target.checked;
        setPermissionFirstColumnList(values)
        setSelectedPermissionDataFirstColumn()
    };

    const handleOnChangeSecondColumn = (event: any, option: any, index: any) => {
        const values = [...permissionSecondColumnList];
        values[index].isAdded = event.target.checked;
        // setFocusArea(values);
        setPermissionFirstSecondList(values)
        setSelectedPermissionDataSecondColumn()
    };

    const handleOnChangeThirdColumn = (event: any, option: any, index: any) => {
        const values = [...permissionThirdColumnList];
        values[index].isAdded = event.target.checked;
        // setFocusArea(values);
        setPermissionThirdColumnList(values)
        setSelectedPermissionDataThirdColumn()
    };
    
//    useEffect(() => {
//     setSelectedPermissionData()
//    }, [capacityPermissionFocusAddedList])

//    const setSelectedPermissionData = () => {
//     let tempArray: any = [];
//     capacityPermissionFocusAddedList.map((data: any) => {
//      if(data.isAdded === true) {
//         tempArray.push(data.id)
//      }
//     });
//     setSelectedPermission(tempArray)
//    }

useEffect(() => {
    setSelectedPermissionDataFirstColumn()
   }, [permissionFirstColumnList])

   const setSelectedPermissionDataFirstColumn = () => {
    let tempArray: any = [];
    permissionFirstColumnList.map((data: any) => {
     if(data.isAdded === true) {
        tempArray.push(data.id)
     }
    });
    setSelectedPermission(tempArray)
   }

   useEffect(() => {
    setSelectedPermissionDataSecondColumn()
   }, [permissionFirstColumnList])

   const setSelectedPermissionDataSecondColumn = () => {
    let tempArray: any = [];
    permissionSecondColumnList.map((data: any) => {
     if(data.isAdded === true) {
        tempArray.push(data.id)
     }
    });
    setSelectedPermission(tempArray)
   }

   useEffect(() => {
    setSelectedPermissionDataThirdColumn()
   }, [permissionThirdColumnList])

   const setSelectedPermissionDataThirdColumn = () => {
    let tempArray: any = [];
    permissionThirdColumnList.map((data: any) => {
     if(data.isAdded === true) {
        tempArray.push(data.id)
     }
    });
    setSelectedPermission(tempArray)
   }

    return (
        <Box className={boxView}>
            <Typography style={{
                fontFamily: 'roboto',
                fontSize: 20,
                fontWeight: 700,
                marginLeft: "20px", marginTop: "20px"
            }} gutterBottom>User Role Editor</Typography>

            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                width: "97%",
                height: "fitContent",
                borderColor: 'white',
                display: "flex",
                flexDirection: "row"
            }}>
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    width: "75%"
                }}>


                    <Box sx={{
                        display: "block",
                        flexDirection: "column",
                        paddingBottom: "10px"
                    }}>
                        <Typography variant="caption" >Select Role and manage its capabilities:
                        </Typography>
                        <Select
                            value={controllerOfAccount}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={handleChange}
                            size="small"
                            placeholder="Administrator"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>Administrator</>;
                                }
                                return selected;
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "100%",
                                marginTop: 7,
                                height: 2
                            }}
                        />
                    </Box>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <CheckBox
                            sx={{ color: BLACK, marginLeft: "5px", "&.Mui-checked": { color: BLACK } }}
                            value="top"
                            label={"Select All"}
                            labelPlacement="end"
                        />
                        <Box sx={{
                            display: "block",
                            flexDirection: "column",
                            paddingBottom: "10px"
                        }}>
                            <Typography variant="caption" style={{ fontWeight: "bold", marginRight: "2px" }}>Quick Filter:
                            </Typography>
                            <Select
                                value={controllerOfAccount}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={handleChange}
                                size="small"
                                placeholder="Administrator"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>Administrator</>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{
                            display: "block",
                            flexDirection: "column",
                            paddingBottom: "10px"
                        }}>
                            <CustomButton
                                placeHolder={"Granted Only"}
                                textTransform="none"
                                background={BLACK}
                                height="25px"
                                alignItems="center"
                                marginRight="10px"
                                marginLeft="20px"
                                width="auto"
                                borderRadius="0px"
                                // onClick={() => validateEmptyData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}>
                            </CustomButton>
                            <Select
                                value={controllerOfAccount}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={handleChange}
                                size="small"
                                placeholder="select Application"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>Administrator</>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <hr
                        style={{
                            color: BLACK,
                            backgroundColor: BLACK,
                            width: "100%",
                            marginTop: 7,
                            height: 2
                        }}
                    />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "coloum",
                        justifyContent: "space-between",
                        // height: "30%",
                        overflowY: "scroll",
                        maxHeight: "700px"
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>

                            <div>
                                <MultiCheckboxComponent
                                    list={permissionFirstColumnList}
                                    handleOnChange={handleOnChangeFirstColumn}
                                    // onChange={(e) => handleOnChange(e, item, index)}
                                />
                            </div>

                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div>
                                <MultiCheckboxComponent
                                    list={permissionSecondColumnList}
                                    handleOnChange={handleOnChangeSecondColumn}
                                />
                            </div>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div>
                                <MultiCheckboxComponent
                                    list={permissionThirdColumnList}
                                    handleOnChange={handleOnChangeThirdColumn}
                                />
                            </div>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "30%"
                }}>
                    <CustomButton
                        placeHolder={"Update Role"}
                        textTransform="none"
                        background={BLACK}
                        height="25px"
                        alignItems="center"
                        marginRight="40px"
                        marginLeft="40px"
                        width="auto"
                        borderRadius="2px"
                        buttonStyle={{
                            SUBHEADER1,
                            float: "right"
                            , marginBottom: 3
                        }}>
                    </CustomButton>
                    <Box sx={{
                        borderRadius: "7.2px", margin: 2, padding: 2,
                        boxShadow: 2,
                        border: 2,
                        width: "97%",
                        // height: "80%",
                        borderColor: 'white',
                        justifyContent: "center"
                    }}>
                        <Typography variant="caption" style={{
                            fontWeight: "bold", alignContent: "center", flexGrow: 1,
                            textAlign: "center", display: "block"
                        }}>Role Setting
                        </Typography>

                        <Box sx={{
                            borderRadius: "7.2px", margin: 2, padding: 2,
                            boxShadow: 2,
                            border: 2,
                            width: "90%",
                            display: "block",
                            // height: "auto",
                            backgroundColor: '#F2F2F2',
                            borderColor: '#F2F2F2',
                            justifyContent: 'center',
                            alignContent: "center",
                        }}>
                            <Typography variant="caption" style={{
                                fontWeight: "bold", flexGrow: 1,
                                textAlign: "center", display: "block"
                            }}>Role Shortcuts
                            </Typography>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 9,
                                    height: 2
                                }}
                            />
                            <CustomButton
                                placeHolder={"Add Role"}
                                textTransform="none"
                                background="#C5D4EB"
                                titleColor="black"
                                height="25px"
                                alignItems="center"
                                width="auto"
                                borderRadius="2px"
                                borderColor="black"
                                buttonStyle={{
                                    SUBHEADER1,
                                    flexGrow: 1,
                                    textAlign: "center", display: "block",
                                    marginBottom: 6,
                                }}>
                            </CustomButton>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 11,
                                    height: 2
                                }}
                            />
                            <CustomButton
                                placeHolder={"Duplicate Role"}
                                textTransform="none"
                                background="#C5D4EB"
                                titleColor="black"
                                height="25px"
                                alignItems="center"
                                width="auto"
                                borderRadius="2px"
                                buttonStyle={{
                                    SUBHEADER1,
                                    flexGrow: 1,
                                    textAlign: "center", display: "block"
                                    , marginBottom: 6
                                }}>
                            </CustomButton>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 11,
                                    height: 2
                                }}
                            />
                        </Box>

                        <Box sx={{
                            borderRadius: "7.2px", margin: 2, padding: 2,
                            boxShadow: 2,
                            marginTop: "50px",
                            border: 2,
                            width: "90%",
                            // height: "90%",
                            backgroundColor: '#F2F2F2',
                            borderColor: '#F2F2F2',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="caption" style={{
                                fontWeight: "bold", flexGrow: 1,
                                textAlign: "center", display: "block"
                            }}>Approve Settings
                            </Typography>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 9,
                                    height: 2
                                }}
                            />

                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px"
                            }}>
                                <Typography variant="caption" style={{ fontWeight: "bold" }}>Search User:
                                </Typography>
                                <TextField label="pm@themyp.com" variant="outlined" size="small" />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px"
                            }}>
                                <Typography variant="caption" style={{ fontWeight: "bold" }}>User Name
                                </Typography>
                                <TextField label="pm@themyp.com" variant="outlined" size="small" />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px"
                            }}>
                                <Typography variant="caption" style={{ fontWeight: "bold" }}>Apply to role:
                                </Typography>
                                <TextField label="pm@themyp.com" variant="outlined" size="small" />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                paddingBottom: "10px"
                            }}>
                                <CustomButton
                                    placeHolder={"Select Restriction"}
                                    textTransform="none"
                                    background="#fa348a"
                                    height="auto"
                                    alignItems="center"
                                    // marginRight="10px"
                                    // marginLeft="20px"
                                    width="auto"
                                    borderRadius="0px"
                                    // onClick={() => validateEmptyData()}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}>
                                </CustomButton>
                                <CustomButton
                                    placeHolder={"Cancel"}
                                    textTransform="none"
                                    background={DARKGREY}
                                    height="25px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginLeft="20px"
                                    width="auto"
                                    borderRadius="0px"
                                    // onClick={() => validateEmptyData()}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}>
                                </CustomButton>
                                <CustomButton
                                    placeHolder={"Update"}
                                    textTransform="none"
                                    background={BLACK}
                                    height="25px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginLeft="20px"
                                    width="auto"
                                    borderRadius="0px"
                                    // onClick={() => validateEmptyData()}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}>
                                </CustomButton>

                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>

        </Box>
    );
}

export default CapacityDialog;
