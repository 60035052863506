import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLUE_2, YELLOW } from "utils/colors";

const UserNameTableStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-around",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "40px",
        marginTop: "20px",
        width: '35%',
        background: "#FFFFFF",
    },

}))
function UserNameTable(props: any) {
    const { boxView } = UserNameTableStyle();
    const width = (window.innerWidth * 80) / 100;
    const [userList, setUserList] = useState(props.userList)
    const columns = [

        // { field: 'Id', headerName: '', width: 60     },
        {
            field: 'Id',
            headerName: '',
            width: width / 6 - 60,
            editable: true,
            renderCell: () => <img src={'/src/assets/User_access_fill.png'} />, // renderCell will render the component
        },
        { field: 'userName', headerName: 'User Name', width: width / 6 },
        { field: 'name', headerName: 'Name', width: width / 6 },
        { field: 'email', headerName: 'Email', width: width / 6 },
        { field: 'role', headerName: 'Role', width: width / 6 },
        { field: 'access', headerName: 'Access', width: width / 6 },

        // {
        //     field: 'userName',
        //     headerName: 'User Name',
        //     type: 'string',
        //     width: 90,
        // },
        // {
        //     field: 'name',
        //     headerName: 'Name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        //     valueGetter: (params: any) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];
    const rows = [
        { id: 1, userName: 'MYP PM', name: 'Ishrut', email: 'pm@themp.com', role: 'Departement Head', access: 'Y-Click' },
        { id: 2, userName: 'LRT MD', name: 'User A', email: 'ap@themp.com', role: 'Departement Head', access: 'Y-Click' },
        // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    useEffect(() => {
        if(props.userList.length === 0){
            getUserList()
        }
      }, [])
    
    const getUserList = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
        const details = JSON.parse(loginUserDetails)
        userId = details.id
        }
        const response = await HomeApi.getUserList(userId);
        if (response.status === true) {
            setUserList(response.data)
            props.setIsUserAvailable(true)
            // let tempArray: any = [];
            // response.data.map((role: any) => {
            // tempArray.push(role.doc)
            // });
            // setRoleNameList(tempArray)
        }
    }

    return (
        <Box className="boxView">
            <div style={{ height: 400, width: '97%', marginTop: "20px", marginLeft: "10px" }}>
                <DataGrid sx={{
                    // boxShadow: 2,
                    // border: 2,
                    // borderColor: 'primary.light',
                    '& .MuiDataGrid-cell': {
                        // color: 'primary.main',
                        backgroundColor: BLUE_2
                    },
                    '& .MuiDataGrid-columnHeader': {
                        //   color: 'primary.main',
                        backgroundColor: YELLOW,
                        width: "100%"
                    },
                }}
                    rows={userList}
                    columns={columns}
                    onRowSelectionModelChange={(row: any) => {
                          userList.map((user: any) => {
                                if(row[0] === user.id){
                                    props.setUserForEdit(user)
                                }
                          })
                    }}
                    // pageSize={5}
                    // rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
        </Box>
    );
}

export default UserNameTable;
