import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Link, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import FilterFunail from "../../../assets/Filter_funail.svg";
import { BLACK } from 'utils/colors';
import './dashboard-app-tab.scss';
import TextInputField from 'components/text-field/text-field';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  appText: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23.3397px",
    lineHeight: "50px",
    color: "#FFFFFF",
    textAlign: "start",
  },
  inputBox: {
    float: "right",
    marginRight: "6px",
    marginLeft: "auto",
  }
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className: string;
  childClassName: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, childClassName } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={className}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>
          <Typography className={childClassName}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DasboardAppsTabs() {
  const [value, setValue] = React.useState(0);
  const { appText, inputBox } = useStyles();
  const { t } = useTranslation()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const appDetail = `${t("YC_DASHBOARD_APP_DETAILS")}`

  return (
    <Box>
      <Box sx={{
        minHeight: "50px",
        borderRadius: "0px",
        backgroundColor: "#000000",
        marginTop: "16px",
      }}
      >
        <Typography sx={{ ml: 2.5 }} className={appText}>
          {t("YC_APPS")}
        </Typography>
      </Box>
      <Box sx={{
        borderRadius: "0px",
        marginTop: "15px",
        textAlign: "start",
      }}
      >
        <Typography sx={{ ml: 2.5 }}>{appDetail}</Typography>
        <Typography sx={{ ml: 2.5 }}>
         {t("YC_APPS_WITH_THE")}  "<SvgIcon component={PowerSettingsNewIcon} sx={{ color: BLACK }} inheritViewBox />" {t("YC_ICONS_ARE_IMPORTANT_APPS")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, }}>
        <Box className="row m-0 p-0" >
          <Box className="col-12 col-md-8 m-0 p-0 " >
            <Tabs value={value} className="tabBtn" onChange={handleChange} aria-label="basic tabs example">
              <Tab label={t("YC_ALL")} {...a11yProps(0)} />
              <Tab label={t("YC_APPLICATION")} {...a11yProps(1)} />
              <Tab label={t("YC_TOOLS")} />
              <Tab label={t("YC_WIDGETS")} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Box className="col-12 col-md-4 m-0 p-0 " >
            <Button variant="outlined" sx={{ color: "Black", float: "right", height: 25, marginRight: 2, mt: 2, borderColor: BLACK }} >{t("YC_FILTER")}</Button>
            <img src={FilterFunail} height={25} style={{ float: "right", marginRight: 10, marginTop: 16 }} color="primary" alt={"icons"} />
          </Box>
        </Box>
        <Box className="d-flex flex-wrap" >
          {
            tabs.map((tab, i) => {
              return <TabPanel className="" childClassName="d-flex flex-wrap" value={value} index={i}>
                {tab.type === "link" &&
                  tab.element.map((subElement) => (
                    <Link color={"#32373C"} href="#" underline="none" className='d-flex flex-colum pt-3 pb-3' sx={{ width: "20%", minWidth: "200px" }}>
                      {subElement}
                    </Link>
                  ))
                }
                {tab.type !== "link" &&
                  tab.element.map((subElement) => (
                    <Typography className='d-flex flex-colum pt-3 pb-3' sx={{ width: "100%", minWidth: "200px" }}>
                      {subElement}
                    </Typography>
                  ))
                }
              </TabPanel>
            })
          }

        <Box className={inputBox} >
          <TextInputField
            label={t("YC_SEARCH_INSTALLED_APPS")}
          // width={"100%"}
          // height={windowHeight * 0.01}
          // value={userName}
          // onChange={(text: any) => {
          //   setUserName(text.target.value);
          //   validateEmptyData();
          // }}
          />
        </Box>
        </Box>
      </Box>
    </Box>
  );
}

const all = [
  "Business Directory",
  "Chat and  Email",
  "Quotation and Tender",
  "e-Market",
  "Customer Relation Management",
  "Accounting",
  "Payment Solution",
  "Human Resources",
  "e-Learning",
  "Appointments",
  "Advertising",
  "Marketing",
  "Search Engine Optimisation",
  "Social Media Marketing",
  "Project Manager",
  "Kirol",
  "Help Desk",
  "Events",
]
const applications = ["Working progress "];
const tabs = [
  { "element": all, type: "link" },
  { "element": applications, type: "other" },
  { "element": applications, type: "other" },
  { "element": applications, type: "other" },
]