import {
  Box,
  Divider,
  SvgIcon,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, WHITE, YELLOW, GREY } from "utils/colors";
import ShortcutSetupProcess from "./shortcut-setup-process";
import DashboardNotification from "./dashboard-notification";
import DashboardMyApplication from "../../../components/dashboard/dashboard-my-application";
import DasboardAppsTabs from "./dashboard-apps-tab";
import StepFooter from "../dashboard-reusables/step-footer";
import React, { useState } from "react";
import DashboardStatusAccordian from "components/dashboard/dashboard-status-accordian/dashboard-status-accordian";
import { AccordionData } from "components/dashboard/dashboard-status-accordian/AccordianData";


const UseStyles = makeStyles(() => ({
  dividerStyle: {
    borderColor: GREY,
    borderWidth: 1.0,
    opacity: 1,
  },
  bannerAdvert: {
    width: "80%",
    minHeight: "90px",
    backgroundColor: "#D9D9D9",
    margin: "auto",
    "@media (max-width: 800px)": {
      width: "100%",
      margin: "0px",
    }
  },

}));

const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function InCompleteDashboard(props: Props) {
  const { dividerStyle,  bannerAdvert } = UseStyles();
  const [isAccessButtonClick, setIsAccessButtonClick] = useState(false)
  const accordionData: AccordionData[] = [
    {
        accordianTitle: "Business Set Up",
        accordianList: [{
            businessStepOne: "Business step 1",
            businessStepTwo: "Business step 2",
            businessStepThree: "Business step 3",
            businessStepFour: "Business step 4"
        }],
    },
    {
        accordianTitle: "Brand Identity",
        accordianList: [{
            businessLogo: "Upload business Logo",
            businessColor: "Set business colours",
            businessSlogan: "Add business slogan",
        }],
    },
    {
        accordianTitle: "Accounting",
        accordianList: [],
    },
    {
        accordianTitle: "Communication",
        accordianList: [],
    },
    {
        accordianTitle: "Dashboard Overview",
        accordianList: [],
    },
    {
        accordianTitle: "Manage your preferances",
        accordianList: [],
    },

]
  return (
    <Box
      className="mx-3 me-lg-4"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        mt: 7,
        ml: 5,

      }}
    >

      <Box
        className="d-flex flex-wrap row m-0 p-0"
        justifyContent="space-between"
      >
        <DashboardNotification />
        {isAccessButtonClick && (<DashboardStatusAccordian accordionData={accordionData} />)}
        {!isAccessButtonClick && (<ShortcutSetupProcess setIsAccessButtonClick={setIsAccessButtonClick}/>)}
        
      </Box>
      <Box sx={{ mt: 5, }}>
        <Divider
          orientation="horizontal"
          flexItem
          className={dividerStyle}
          style={{ borderColor: "rgba(0, 0, 0, 0.5)", borderWidth: 0.5 }}
        />
      </Box>
      <Box sx={{
        marginTop: 4,
      }}
      >
        <Box className={bannerAdvert} >

        </Box>

      </Box>

      <Box sx={{ mt: 4 }}>
        <DashboardMyApplication />
      </Box>
      <Box sx={{ mt: 4, }}>
        <Divider
          orientation="horizontal"
          flexItem
          className={dividerStyle}
          sx={{ mb: 2, marginTop: 0.2 }}
          style={{ borderColor: "rgba(0, 0, 0, 0.5)", borderWidth: 0.5 }}
        />
      </Box>
      <DasboardAppsTabs />
      <StepFooter
        text={"Do you need assistance? "}
        link={"Click here to access Tutorials"}
      />
    </Box>
  );
}

export default InCompleteDashboard;
















