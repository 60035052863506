import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { BLACK, BLUE, GREY, INFO, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import CustomSelect from "components/custom-select/custom-select";
import { countries } from "utils/countries";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import SuccessIcon from "../../../assets/success.png";
import Error from "../../../assets/error.png";
import HomeApi from "services/HomeAPi";
import { validateEmail, validateMobilePhone } from "utils/helper";
import { YC_EMAIL_VALID, YC_PHONE_VALID } from "utils/strings";
import Notification from "views/Notifications/Notification";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "7.2px",
    width: "97.5%",
    background: "#FFFFFF",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
  buttonStyle: {
    marginTop: '40px',
    display: "flex",
    width: "94%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  }
}));

function ControllerProcessFour(props: any) {
  const [controllerOfAccount, setControllerOfAccount] = React.useState("");
  const [anotherUser, setAnotherUser] = React.useState("");
  const [additionalUser, setAdditionalUser] = React.useState("");
  const [isValidData, setIsValidData] = React.useState(false)
  const [isDisplayCountLabel, setIsDisplayCountLabel] = React.useState(false)
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState({value: true, error: ""})
  const [isValidEmail, setIsValidEmail] = React.useState({value: true, error: ""})
  const [selectedAdditionalUserCount, setSelectedAdditionalUserCount] = React.useState(0)

  const [inputData, setInputData] = React.useState({
    title: "",
    firstName: "",
    lastName: "",
    code: "",
    mobilePhone: "",
    email: "",
    userRole: ""
  });

  const { titleText, buttonStyle } = useStyles();
  const { t } = useTranslation()

  useEffect(() => {
    getControllerSetupData()
  }, [])

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const getControllerSetupData = async () => {
    const response = await HomeApi.getControllerSetupData(getUserId())
    if(response.status === true){
      setSelectedAdditionalUserCount(response.data.selectedAdditionalUserCount)
    }
  }

  const handleChange = (key: any, event: SelectChangeEvent) => {
    setIsValidPhoneNumber({value: true, error: ""})
    setIsValidEmail({value: true, error: ""})
    if (key === 'user_role') {
      setInputData((prevFields) => ({
        ...prevFields,
        userRole: event.target.value,
      }));
    } else if (key === 'title') {
      setInputData((prevFields) => ({
        ...prevFields,
        title: event.target.value,
      }));
    } else if (key === 'code') {
      setInputData((prevFields) => ({
        ...prevFields,
        code: event.target.value,
      }));
    }
  };

  const isValid = () => {
    if (inputData.title === "" || inputData.firstName === "" || inputData.lastName === "" || inputData.email === "" || 
    inputData.code === "" || inputData.mobilePhone === "" || inputData.userRole === "") {
      setIsValidData(false)
      return false;
    } else {
      return setIsValidData(true);
    }
  };

  const clearAdditionalUserData = () => {
    setInputData({
      title: "",
      firstName: "",
      lastName: "",
      code: "",
      mobilePhone: "",
      email: "",
      userRole: ""
    })
    setIsValidData(false)
  }
  
  const postSetupStepCount = async () => {
    const payload = {
      setup_count: 1,
      step_count: 5
    };

    const response = await HomeApi.postSetupStepCount(payload);
  };

  const postAdditionalUserDetails = async (additionalUserDetails: any) => {
    localStorage.setItem("controllerStep", "5")
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    const payload = {
        title: additionalUserDetails.title,
        fname: additionalUserDetails.firstName,
        lname: additionalUserDetails.lastName,
        code: additionalUserDetails.code,
        mobile: additionalUserDetails.mobilePhone,
        email: additionalUserDetails.email,
        userrole: additionalUserDetails.userRole === "General User" ? "Guser" : additionalUserDetails.userRole,
        controller: "No",
        user_id: userId,
        invitation_url: `https://y-click.io/invite/${additionalUserDetails.firstName}/${additionalUserDetails.lastName}/${additionalUserDetails.email}/${additionalUserDetails.mobilePhone}`,
        setup_count: 1,
        step_count: props.additionalUserCount ? 5 : 4
    }
    const response = await HomeApi.postAdditionalUserDetails(payload);
    if (response.status === true){
        clearAdditionalUserData()
        setIsDisplayCountLabel(true)
       if (localStorage.getItem("additionalUserCount") !== null) {
        let numberOfAdditionalUser = localStorage.getItem('additionalUserCount');
        if (numberOfAdditionalUser){
          localStorage.setItem("additionalUserCount",`${+numberOfAdditionalUser+1}`)
        }
      } else {
        localStorage.setItem("additionalUserCount",`${1}`)
      }

     let countOfAdditionalUserInStep3
      if (localStorage.getItem("additionalUserCountInStep3") !== null) {
         countOfAdditionalUserInStep3 = localStorage.getItem('additionalUserCountInStep3');
      }

      let countOfAdditionalUserInStep4
      if (localStorage.getItem("additionalUserCount") !== null) {
        countOfAdditionalUserInStep4 = localStorage.getItem('additionalUserCount');
      }

      if(countOfAdditionalUserInStep3 === countOfAdditionalUserInStep4){
        postSetupStepCount()
        props.setStepNumber(5)
        props.goToNext(5)
        localStorage.removeItem("additionalUserCountInStep3")
        localStorage.removeItem("additionalUserCount")
      } else if(selectedAdditionalUserCount === Number(countOfAdditionalUserInStep4)) {
        postSetupStepCount()
        props.setStepNumber(5)
        props.goToNext(5)
        localStorage.removeItem("additionalUserCountInStep3")
        localStorage.removeItem("additionalUserCount")
      }
    } else {
      Notification.notifyError(response.message);
    }
  }

  const validatePhone = (mobilePhone: string) => {
    const validMobilePhone = validateMobilePhone(mobilePhone);
    return validMobilePhone;
  };

  useEffect(() => {
    isValid()
  }, [inputData.title, inputData.firstName, inputData.lastName, inputData.email, 
  inputData.code, inputData.mobilePhone, inputData.userRole])

  const renderIcon = (iconName: any) => {
    return (
      <img
        src={iconName}
        height={22}
        alt={"icon"}
      />
    );
  };

  const selectFieldView = (key: any, label: any, items: any, value: any) => {
    return (
      <CustomSelect
        label={label}
        minWidth={key === 'user_role' ? '97%' : 80}
        selectStyle={{ marginTop: "20px" }}
        items={items}
        value={value}
        onChange={(event: any) => {
          if (key === "title") {
            handleChange('title', event)
          } else if (key === "code") {
            handleChange('code', event)
          } else if (key === "user_role") {
            handleChange('user_role', event)
          }
        }}
      />
    );
  };
  const dummyView = () => {
    return <Box sx={{ display: "flex", minWidth: 80 }} />;
  };

  const dummyViewToJustSmallView = () => {
    return <Box sx={{ display: "flex", minWidth: 80, height: 2 }} />;
  };
  const selectionView = (
    key: any,
    text: any,
    items: any,
    value: any
  ) => {
    return (
      <CustomTitleSelect
        title={text}
        value={value}
        onChange={(event: any) => {
          if (key === 'account_controller') {
            setControllerOfAccount(event.target.value)
          } else if (key === 'another_user') {
            setAnotherUser(event.target.value)
          } else if (key === 'additional_user') {
            setAdditionalUser(event.target.value)
          }
        }}
        items={items}
        boxStyle={{
          width: "44%",
          height: '80%',
          minWidth: "82%",
          marginLeft: '25px',
          marginTop: '22px'
        }}
        canSelect={false}
      />

    );
  };

  const textFieldView = (
    key: any,
    label: any = "",
    value: any,
    width: any = "40%"
  ) => {
    return (
      <FormControl
        sx={{
          display: "flex",
          width: width,
          minWidth: "230px",
          m: 1,
          mt: 2.5,
        }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          endAdornment={
            value?.length > 1 && ((isValidPhoneNumber.error && key === "mobile_phone") ||
            (isValidEmail.error && key === "email")) ?
            <InputAdornment position="end">
              {renderIcon(Error)}
            </InputAdornment> :
            <InputAdornment position="end">
            {renderIcon(SuccessIcon)}
          </InputAdornment>
          }
          label={label}
          value={value}
          onChange={(text) => {
            const trimStr = text.target.value.trimStart();
            if (key === "first_name") {
              setInputData((prevFields) => ({
                ...prevFields,
                firstName: trimStr,
              }));
            } else if (key === "last_name") {
              setInputData((prevFields) => ({
                ...prevFields,
                lastName: trimStr,
              }));
            } else if (key === "mobile_phone") {
              const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
                const isValidPhoneNumber = validatePhone(mob);
                setIsValidPhoneNumber({value:isValidPhoneNumber, error: !isValidPhoneNumber ? YC_PHONE_VALID : ""})
              setInputData((prevFields) => ({
                ...prevFields,
                mobilePhone: trimStr,
              }));
            } else if (key === "email") {
              const validEmail = validateEmail(value);
                setIsValidEmail({value: validEmail, error: !validEmail ? YC_EMAIL_VALID : ""})
              setInputData((prevFields) => ({
                ...prevFields,
                email: trimStr,
              }));
            } else if (key === "user_role") {
              setInputData((prevFields) => ({
                ...prevFields,
                userRole: trimStr,
              }));
            }
            isValid()
          }}
          error={(!!isValidPhoneNumber.error && key === "mobile_phone") ||
             (!!isValidEmail.error && key === "email")}
        />
        {!!isValidPhoneNumber.error && key === "mobile_phone" && (
            <FormHelperText error id="validNumber-error">
              {isValidPhoneNumber.error}
            </FormHelperText>
          )}
          {!!isValidEmail.error && key === "email" && (
            <FormHelperText error id="validEmail-error">
              {isValidEmail.error}
            </FormHelperText>
          )}
      </FormControl>
    );
  };

  const stepInfo = (
    textOne: any = "",
    textTwo: any = "",
    bgColor: any = INFO,
    maxHeight: any = "56px"
  ) => {
    return (
      <StepInformation
        boxStyle={{ marginTop: "16px", marginLeft: '20px' }}
        width={"90%"}
        minWidth={"85%"}
        // maxWidth={"44%"}
        maxHeight={maxHeight}
        textAlign={"center"}
        bgColor={bgColor}
        textColor={WHITE}
        textOne={textOne}
        textTwo={textTwo}
      />
    );
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "3%",
        width: "100%",
      }}
    >
      <Typography className={titleText} sx={{ display: "flex" }}>
        {t("YC_CONTROLLER_STEP_4_TITLE")}
      </Typography>
      <Box
        sx={{
          display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
          flexDirection: 'row',
        }}
      > 
         <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: 'column',
              width: '100%'
            }}
          >
              <Box
                sx={{
                  display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                  flexDirection: 'row',
                }}
              >
                {selectFieldView(
                  "title",
                  t("YC_TITLE_LABEL"),
                  [t("YC_MR"), t("YC_MRS", t("YC_MS"))],
                  inputData.title
                )}
                {textFieldView(
                  "first_name",
                  t("YC_FIRST_NAME"),
                  inputData.firstName,
                  "80%"
                )}
              </Box>
              {dummyView()}
              <Box
                sx={{
                  display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                  flexDirection: 'row',
                }}
              >
                {dummyViewToJustSmallView()}
                {textFieldView(
                "last_name",
                t("YC_LAST_NAME"),
                inputData.lastName,
                "80%"
              )}
              </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: 'column',
              width: '100%'
            }}
          >
              {stepInfo(t("YC_CONTROLLER_SETUP_4_INFO_1"), "", INFO)}
              <Box sx={{ display: "flex", minWidth: 50, height: 10 }} />
              {props.isAnotherUser === 'Yes' && props.isUserController === 'No' && stepInfo(
                t("YC_CONTROLLER_SETUP_3_NON_CONTROLLER_RED_INFO_1"),
                t("YC_CONTROLLER_SETUP_3_NON_CONTROLLER_RED_INFO_2"),
                RED,
                "120px"
              )}
          </Box>
        
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
          flexDirection: 'row',
        }}
      > 
         <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: 'column',
              width: '100%'
            }}
          >
              <Box
                sx={{
                  display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                  flexDirection: 'row',
                }}
              >
                {selectFieldView(
                  "code",
                  t("YC_CODE"),
                  countries.map((item: any) => {
                    return item.phone;
                  }),
                  inputData.code
                )}
                {textFieldView(
                  "mobile_phone",
                  t("YC_MOBILE_NUMBER"),
                  inputData.mobilePhone,
                  "80%"
                )}
              </Box>
              {dummyView()}
              <Box
                sx={{
                  display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                  flexDirection: 'row',
                }}
              >
                {dummyViewToJustSmallView()}
                {textFieldView("email", t("YC_EMAIL_OF_USER"), inputData.email, "80%")}
              </Box>
              {dummyView()}
              <Box
                sx={{
                  display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                  flexDirection: 'row',
                }}
              >
                {dummyViewToJustSmallView()}
                <Box
                  sx={{
                    width: '82.5%',
                    marginLeft: 0
                  }}
                >
                  {selectFieldView(
                    "user_role",
                    t("YC_USER_ROLE"),
                    props.isUserController === 'Yes' ? ["Administrator", "General User"] : ["General User"],
                    inputData.userRole
                  )}
                </Box>
              </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: 'column',
              width: '100%'
            }}
          >
              {selectionView(
                "account_controller",
                `${t("YC_ARE_YOU_CONTROLLER")}:`,
                [],
                props.isUserController
              )}
              <Box sx={{ display: "flex", minWidth: 50, height: 20 }} />
              {selectionView(
                "another_user",
                `${t("YC_SETUP_ADDITIONAL_USERS")}:`,
                [],
                props.isAnotherUser
              )}
              <Box sx={{ display: "flex", minWidth: 50, height: 20 }} />
              {selectionView(
                "additional_user",
                `${t("YC_NUMBER_OF_ADDITIONAL_USER")}:`,
                [],
                props.isAdditionalUser
              )}
              {isDisplayCountLabel &&
                <Box
                  sx={{
                    display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                    flexDirection : 'row',
                    ml: 4,
                    justifyContent:'flex-start'
                  }}
                >
                  <label style={{ color:RED}}>{t("YC_ADDITIONAL_USER_AVAILABLE")} : (1)</label>
                </Box>
              }
          </Box>
        
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "95%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "140px",
          }}
          onClick={() => {
            localStorage.setItem("controllerStep", "3")
            props.goToNext(3, "", "", true)
          }}
        />
        <CustomButton
          disabled={!isValidData}
          placeHolder={t("YC_SUBMIT")}
          textTransform="none"
          background={isValidData ? BLACK : GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "140px",
          }}
          onClick={() => {
            postAdditionalUserDetails(inputData)
          }}
        />
      </Box>
      {/* <Box
      className={buttonStyle}
      >
        <CustomButton
          disabled={!isValidData}
          placeHolder={t("YC_SUBMIT")}
          textTransform="none"
          background={isValidData ? BLACK : GREY}
          height="40px"
          width={"10%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            postAdditionalUserDetails(inputData)
            // props.goToNext(inputData);
          }}
        />
      </Box> */}
    </Box>
  );
}
export default ControllerProcessFour;
