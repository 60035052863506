import {
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect, useState } from "react";
import { BLACK, BLUE, GREY, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import { Height } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "8px",
    width: "97.5%",
    background: "#FFFFFF",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
}));

function ControllerProcessThree(props: any) {
  const [controllerOfAccount, setControllerOfAccount] = React.useState(
    props.isUserController
  );
  const [anotherUser, setAnotherUser] = React.useState("");
  const [additionalUser, setAdditionalUser] = React.useState("");
  const [isExceedLimit, SetIsExceedLimit] = useState(false);
  const [additionalUserCountFromData, setAdditionalUserCountFromData] = React.useState("");

  const { titleText } = useStyles();
  const { t } = useTranslation()

  useEffect(() => {
    getControllerSetupData()
  }, [])

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const postSetupStepCount = async () => {
    const payload = {
      setup_count: anotherUser === "Yes" ? 1 : 2,
      step_count: anotherUser === "Yes" ? 4 : 1
    };

    const response = await HomeApi.postSetupStepCount(payload);
  };

  const actionOnClick = async () => {
    const payload = {
      anotheruser : anotherUser,
      selectedAdditionalUserCount: Number(additionalUser)
    }
    const response = await HomeApi.postOptionAddOtherUser(payload);
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      postOptionAddOtherUser()
    } else {
      Notification.notifyError(response.message)
    }
  }

  const postOptionAddOtherUser = () => {
    postSetupStepCount()
    localStorage.setItem("controllerStep", "4")
    if (anotherUser === "Yes") {
      nextSetupAction();
    } else {
      CompleteSetupAction();
    }
  }

  const nextSetupAction = () => {
    if (isExceedLimit) {
      props.goToNext(5)
    } else {
      props.goToNext(controllerOfAccount, anotherUser, additionalUser);
    }
  }
  const CompleteSetupAction = () => {
    props.goToNext("business_setup")
    localStorage.setItem("businessStep", "1")
  }

  useEffect(() => {
    const getUserDataFromLocalStorage = localStorage.getItem("user_Details")
    const getLoginUserDataFromLocalStorage = localStorage.getItem("login_user_Details")
    if (getUserDataFromLocalStorage && getLoginUserDataFromLocalStorage) {
      const data = JSON.parse(getUserDataFromLocalStorage)
      const loginUserdata = JSON.parse(getLoginUserDataFromLocalStorage)
      if (data.id === loginUserdata.id) {
        setControllerOfAccount(data.controller)
      }
    }
  }, []);
  
  const getControllerSetupData = async () => {
    const response = await HomeApi.getControllerSetupData(getUserId())
    if(response.status === true){
      setAnotherUser(response.data.anotheruser)
      setAdditionalUser(response.data.additiona_user)
      setAdditionalUserCountFromData(response.data.additiona_user)
      if(response.data.anotheruser = "Yes" && response.data.additiona_user === "2") {
        SetIsExceedLimit(true)
      } else {
        SetIsExceedLimit(false)
      }
    }
  }

  const selectionView = (
    key: any,
    text: any,
    items: any,
    value: any,
    canSelect = true
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '35%'
        }}
      >
        <CustomTitleSelect
          title={text}
          value={value}
          onChange={(event: any) => {
            if (key === "another_user") {
              setAnotherUser(event.target.value);
              setAdditionalUser("");
            } else if (key === "additional_user") {
              setAdditionalUser(event.target.value);
            }
          }}
          items={items}
          boxStyle={{
            marginBottom: '35px',
            width: { sx: '100%', sm: '100%', lg: '100%', xl: '100%' }
          }}
          canSelect={canSelect}
        />
      </Box>
    );
  };

  const infoViewOfController = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", pr: 3, alignItems: 'flex-end' }}>
        <StepInformation
          minWidth={"90%"}
          boxStyle={{ marginBottom: "20px" }}
          bgColor={"rgba(15, 92, 208, 0.8)"}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_3_INFO_1")}
          textTwo={t("YC_CONTROLLER_SETUP_3_INFO_2")}
        />
        <StepInformation
          minWidth={"90%"}
          bgColor={"rgba(202, 31, 31, 0.8)"}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_3_RED_INFO_1")}
        />
        {/* <StepInformation
            minWidth={"90%"}
            bgColor={"rgba(202, 31, 31, 0.8)"}
            textColor={WHITE}
            title={"FOR YOUR INFORMATION"}
            textOne={
              "After Submitting; you will have basic user access. Controller are required to set your role and capacity."
            }
          /> */}
      </Box>
    )
  }

  const infoViewOfAdditionalUserSelection = () => {
    return (
      <StepInformation
        minWidth={"50%"}
        // boxStyle={{ marginBottom: "20px" }}
        bgColor={"rgba(15, 92, 208, 0.8)"}
        textColor={WHITE}
        textOne={t("YC_CONTROLLER_SETUP_3_ADDITIONAL_USER_INFO_1")}
        textTwo={t("YC_CONTROLLER_SETUP_3_ADDITIONAL_USER_INFO_2")}
      />
    )
  }
  const infoViewOfNonController = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", pr: 3, alignItems: 'flex-end' }}>
        <StepInformation
          minWidth={"90%"}
          boxStyle={{ marginBottom: "20px" }}
          bgColor={"rgba(15, 92, 208, 0.8)"}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_3_ADDITIONAL_USER_INFO_1")}
          textTwo={t("YC_CONTROLLER_SETUP_3_ADDITIONAL_USER_INFO_2")}
        />
        <StepInformation
          minWidth={"90%"}
          bgColor={"rgba(202, 31, 31, 0.8)"}
          textColor={WHITE}
          title={t("YC_CONTROLLER_SETUP_3_NON_CONTROLLER_RED_INFO_1")}
          textOne={t("YC_CONTROLLER_SETUP_3_NON_CONTROLLER_RED_INFO_2")}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        m: 3,
        // width: "97%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Typography className={titleText} sx={{ display: "flex" }}>
        {additionalUser?.length > 0
          ? t("YC_CONTROLLER_STEP_3_TITLE_ADDITIONAL_USER_NUMBER") 
          : t("YC_CONTROLLER_STEP_3_TITLE_ADDITIONAL_USER")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "space-between",
          mt: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", minWidth: { xs: '100%', sm: 0, lg: 0, xl: 0 } }}>
          {selectionView(
            "account_controller",
            `${t("YC_ARE_YOU_CONTROLLER")}:`,
            [],
            controllerOfAccount,
            false
          )}
          <Box sx={{ display: "flex", minWidth: 10, height: { xs: 5, sm: 0, lg: 0, xl: 0 } }} />
          {selectionView(
            "another_user",
            t("YC_DO_YOU_WANT_ADD_USER"),
            ["Yes", "No"],
            anotherUser
          )}
          <Box sx={{ display: "flex", minWidth: 10, height: { xs: 5, sm: 0, lg: 0, xl: 0 } }} />
          {anotherUser === "Yes" ? selectionView(
            "additional_user",
            `${t("YC_NUMBER_OF_ADDITIONAL_USER")}:`,
            props.additionalUserCount === 1 || (anotherUser === "Yes" && Number(additionalUserCountFromData) === 1) ? ["1"] : ["1", "2"],
            additionalUser
          ) : null}
        </Box>
        {
          props.isUserController === "No" ? infoViewOfNonController() :
            anotherUser === "Yes" ? infoViewOfAdditionalUserSelection() : infoViewOfController()
        }
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "flex-end",
          width: { xs: "93%", sm: "93%", lg: "98%", xl: "98%" },
          mb: 3,
          mt: 3,
        }}
      >
        <CustomButton
          disabled={
            anotherUser === "No" ? false : controllerOfAccount && additionalUser ? false : true
          }
          placeHolder={anotherUser === "Yes" ? t("YC_NEXT_STEP") : t("YC_COMPLETE_SETUP")}
          textTransform="none"
          background={
            anotherUser === "No" ? BLUE : controllerOfAccount && additionalUser ? BLUE : GREY
          }
          height="40px"
          width={"20%"}
          borderRadius="8"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            actionOnClick();
          }}
        />
      </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "140px",
          }}
          onClick={() => {
            localStorage.setItem("controllerStep", "2")
            props.goToNext(2, "", "", true)
          }}
        />
        <CustomButton
          disabled={
            anotherUser === "No" ? false : controllerOfAccount && additionalUser ? false : true
          }
          placeHolder={anotherUser === "Yes" ? t("YC_NEXT_STEP") : t("YC_COMPLETE_SETUP")}
          textTransform="none"
          background={
            anotherUser === "No" ? BLUE : controllerOfAccount && additionalUser ? BLUE : GREY
          }
          height="40px"
          width={"20%"}
          borderRadius="8"
          marginLeft={"10px"}
          buttonStyle={{
            HEADER2,
            minWidth: "140px",
          }}
          onClick={() => {
            actionOnClick();
          }}
        />
      </Box>
    </Box>
  );
}
export default ControllerProcessThree;
