import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { BLACK, BLACK_2, BLUE, GREY, GREY_2, INFO, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import { validateEmail, validateMobilePhone } from "utils/helper";
import { YC_EMAIL_VALID, YC_PHONE_VALID } from "utils/strings";
import Notification from "views/Notifications/Notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "7.2px",
    width: "97.5%",
    background: "#FFFFFF",
  },
  titleText: {
    color: BLACK,
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 1,
  },
  labelStyle: {
    marginTop: 10,
    color: BLACK_2,
    fontSize: 12,
    fontWeight: 600,
    minWidth: '80px',
    textAlign: 'start'
  },
  labelStyleForRowTextField: {
    marginTop: 10,
    color: BLACK_2,
    fontSize: 12,
    fontWeight: 600,
    minWidth: '60px',
    textAlign: 'start'
  }
}));

function ControllerProcessFive(props: any) {
  const [controllerOfAccount, setControllerOfAccount] = React.useState("");
  const [roleCapacity, setRoleCapacity] = React.useState("");
  const [controllerRoleName, setControllerRoleName] = React.useState("Controller - Kevin Koborg")
  const [isUserController, setIsUserController] = React.useState("No")
  const [additionalUsers, setAdditionalUsers] = React.useState([]);
  const [capacity, setCapacity] = React.useState([]);
  const [capacityName, setCapacityName] = React.useState([]);
  const [selectedCapacity, setSelectedCapacity] = React.useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState({ value: true, error: "" })
  const [isValidEmail, setIsValidEmail] = React.useState({ value: true, error: "" })
  const [isEdiTable, setIsEdiTable] = React.useState(true);
  const [roleName, setRoleName] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);
  const [nameAndRole, setNameAndRole] = React.useState([])
  const [applicationList, setApplicationList] = React.useState([])
  const [applicationNameList, setApplicationNameList] = React.useState([])
  const [selectedApp, setSelectedApp] = React.useState("");
  const [inputData, setInputData] = React.useState({
    id: "0",
    parent_id: "0",
    created_at: "",
    updated_at: "",
    controller: "Yes",
    title: "Mr",
    name: "",
    lname: "",
    email: "",
    role: "controller",
    mobile: "",
    contrycode: "230",
    fixlinenumber: "",
    positioncontroller: "",
    additiona_user: "2",
    companyName: "",
    BRN: "",
    source: "",
    source_identifier: "0",
    type: "Business",
    yclickpermission_id: "0",
    yclick_settting_id: "",
    displayMobileNo: ""
})

const [primaryRoleList, setPrimaryRoleList] = React.useState([])
const [primarySelectedRole, setPrimarySelectedRole] = React.useState("");
const [primaryRoleNameList, setPrimaryRoleNameList] = React.useState([])

  const [capacityFeature, setCapacityFeature] = React.useState([
    { value: 'Business Details', subFeature: ["Opening Hours", "Location", "Payment Types Accepted", "Business Media", "Social Networks", "Brands", "Branches"] },
    { value: "Catalogue", subFeature: [] },
    { value: "Coupons", subFeature: [] },
    { value: "Your Business Reviews", subFeature: [] }])
  const { titleText, labelStyle, labelStyleForRowTextField } = useStyles();
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getUserNameAndRole = async () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    const response = await HomeApi.getUserNameAndRole(userId);
    if (response.status === true) {
      setNameAndRole(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push(element.role_name)
      });
      setRoleName(tempArray)
    }
  }

  const getAdditionalUser = async () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    const response = await HomeApi.getAdditionalUser(userId);
    if (response.status === true) {
      setAdditionalUsers(response.data)
    }
  }

  const getCapacity = async () => {
    const response = await HomeApi.getCapacityList();
    if (response.status === true) {
      setCapacity(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push(element.name)
      });
      setCapacityName(tempArray)
    }
  }

  const getApplicationList = async () => {
    const response = await HomeApi.getApplicationList();
    if (response.status === true) {
        setApplicationList(response.data)
        let tempArray: any = [];
        response.data.map((app: any) => {
        tempArray.push(app.application_name)
        });
        setApplicationNameList(tempArray)
    }
}

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
}

  const getPrimaryRole = async () => {
    const response = await HomeApi.getPrimaryRole(getUserId());
    if (response.status === true) {
        setPrimaryRoleList(response.data)
        let tempArray: any = [];
        response.data.map((role: any) => {
        tempArray.push(role.name)
        });
        tempArray.push("other role")
        setPrimaryRoleNameList(tempArray)
    }
}

  const editAdditionalUserDetails = async (userDetails: any) => {
    const payload = {
      id: inputData.id,
      title: inputData.title,
      fname: inputData.name,
      lname: inputData.lname,
      code: inputData.contrycode,
      mobile: inputData.mobile,
      email: inputData.email,
      userrole: inputData.role,
      permission: inputData.yclickpermission_id
    }

    const response = await HomeApi.updateAddtionalUserDetails(payload);
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      setIsEdiTable(true)
      getUserNameAndRole()
      getAdditionalUser()
    } else {
      Notification.notifyError(response.message)
    }
  };

  useEffect(() => {
    getUserNameAndRole()
    getAdditionalUser()
    getCapacity()
    getApplicationList()
    getPrimaryRole()
  }, []);

  useEffect(() => {
      const loginUserDetails = localStorage.getItem("user_Details")
      if (loginUserDetails){
        const userDetails = JSON.parse(loginUserDetails)
        const selectedRoleName: any = nameAndRole.filter((roleName: any) => (roleName.id === userDetails.id))
        setControllerOfAccount(selectedRoleName[0]?.role_name);
        setControllerRoleName(selectedRoleName[0]?.role_name)
    }
}, [nameAndRole]);

useEffect(() => {
    const loginUserDetails = localStorage.getItem("user_Details")
    additionalUsers.map((user: any) => {
      if (loginUserDetails){
        const userDetails = JSON.parse(loginUserDetails)
        if (user.id === userDetails.id){
                setSelectedUser(user)
                setInputData({
                  id: user.id,
                  parent_id: user.parent_id,
                  created_at: user.created_at,
                  updated_at: user.updated_at,
                  controller: user.controller,
                  title: user.title,
                  name: user.name,
                  lname: user.lname,
                  email: user.email,
                  role: user.role,
                  mobile: user.mobile,
                  contrycode: user.contrycode,
                  fixlinenumber: user.fixlinenumber,
                  positioncontroller: user.positioncontroller,
                  additiona_user: user.additiona_user,
                  companyName: user.companyName,
                  BRN: user.BRN,
                  source: user.source,
                  source_identifier: user.source_identifier,
                  type: user.type,
                  yclickpermission_id: user.yclickpermission_id,
                  yclick_settting_id: user.yclick_settting_id,
                  displayMobileNo: user.contrycode + user.mobile
              })
            }
      }
    })
}, [additionalUsers]);

const postSetupStepCount = async () => {
  const payload = {
    setup_count: 2,
    step_count: 1
  };

  const response = await HomeApi.postSetupStepCount(payload);
};

  const CompleteSetupAction = () => {
    postSetupStepCount()
    localStorage.setItem("controllerStep", "5")
    props.goToNext("business_setup")

    // let isSetUpComplete
    // const loginUserDetails = localStorage.getItem("user_Details")
    //     if (loginUserDetails) {
    //         const details = JSON.parse(loginUserDetails)
    //         isSetUpComplete = details.isSetupComplete
    //     }

    // if (isSetUpComplete === "1"){
    //   navigate("/incomplete-dashboard")
    // } else {
    //   props.goToNext("business_setup")
    // }
  }

  const selectionView = (
    key: any,
    text: any,
    items: any,
    value: any
  ) => {
    return (
      <CustomTitleSelect
        title={text}
        value={value}
        onChange={(event: any) => {
          if (key === 'role_capacity') {
            setRoleCapacity(event.target.value);
          }
        }}
        items={items}
        boxStyle={{
          width: "44%",
          minWidth: "320px",
          marginBottom: '40px'
        }}
        canSelect={true}
        displayNA={props.isUserController === "No" ? true : false}
      />
    );
  };
  
  const renderSelect = (key: any, inputLabel: any, value: any, items: any) => {
    return (
      <FormControl sx={{ width: { xs: "92%", sm: "95%", lg: "60%", xl: "50%" }, borderRadius: 2, mr: "5%", mb: 1 }}>
        <Select
          style={{
            border: 1,
            textAlign:'start'
          }}
          value={value}
          onChange={(event) => {
            const selectedRoleName: any = nameAndRole.filter((roleName: any) => (roleName.role_name === event.target.value))
            setControllerOfAccount(selectedRoleName[0]?.role_name);
            additionalUsers.map((user: any) => {
              if (user.id === selectedRoleName[0]?.id){
                setSelectedUser(user)
                setInputData({
                  id: user.id,
                  parent_id: user.parent_id,
                  created_at: user.created_at,
                  updated_at: user.updated_at,
                  controller: user.controller,
                  title: user.title,
                  name: user.name,
                  lname: user.lname,
                  email: user.email,
                  role: user.role,
                  mobile: user.mobile,
                  contrycode: user.contrycode,
                  fixlinenumber: user.fixlinenumber,
                  positioncontroller: user.positioncontroller,
                  additiona_user: user.additiona_user,
                  companyName: user.companyName,
                  BRN: user.BRN,
                  source: user.source,
                  source_identifier: user.source_identifier,
                  type: user.type,
                  yclickpermission_id: user.yclickpermission_id,
                  yclick_settting_id: user.yclick_settting_id,
                  displayMobileNo: user.contrycode + user.mobile
              })
              }
            })
            setIsEdiTable(true)
          }}
        >
          {items.map((item: any) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  };

  const textFieldView = (key: string, label: string = "", value: string, defaultValue: string) => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: { xs: "80%", sm: "50%", lg: '50%', xl: '50%' },
        justifyContent: 'space-between',
      }}
      >
        <label className={labelStyle}> {label} : </label>
        <Box sx={{ width: '20%' }}></Box>
        {key === "mobile_number" ? 
        <Box sx={{ width: { xs: "70%", sm: "71%", lg: '71%', xl: '72%' }}}>
          <PhoneInput
                disabled={isEdiTable}
                country={"ma"}
                value={value}
                containerStyle={{marginLeft: 30, border:'none', height:30, background:'#F9F9FA'}}
                buttonStyle={{background:'#F9F9FA', border:'none'}}
                inputStyle={{border: 'none', width: 200, height:30, background: '#F9F9FA', fontSize: 12, color: isEdiTable ? GREY_2 : BLACK}}
                onChange={(phone: string, country: any) => {
                  const reducedPhone = phone.replace(
                    country.dialCode,'',);
                    setInputData((prevFields) => ({
                      ...prevFields,
                      displayMobileNo: phone,
                      mobile: reducedPhone,
                      contrycode: country.dialCode
                    }))
                }} 
          /> 
        </Box>
        :
        <TextField
          sx={{borderColor: 'blue'}}
          disabled={isEdiTable}
          InputProps={{ disableUnderline: isEdiTable ? true : false, style: { fontSize: 12, marginTop: 9, color: BLACK, minWidth: '200px' } }}
          variant="standard" size="small"
          value={value}
          defaultValue={defaultValue}
          onChange={(text) => {
            const trimStr = text.target.value.trimStart();
            if (key === "user_name") {
              setInputData((prevFields) => ({
                ...prevFields,
                userName: trimStr,
              }));
            } else if (key === "first_name") {
              setInputData((prevFields) => ({
                ...prevFields,
                name: trimStr,
              }));
            } else if (key === "last_name") {
              setInputData((prevFields) => ({
                ...prevFields,
                lname: trimStr,
              }));
            } else if (key === "email") {
              const validEmail = validateEmail(value);
              setIsValidEmail({ value: validEmail, error: !validEmail ? YC_EMAIL_VALID : "" })
              setInputData((prevFields) => ({
                ...prevFields,
                email: trimStr,
              }));
            } else if (key === "mobile_number") {
              setInputData((prevFields) => ({
                ...prevFields,
                mobile: trimStr,
              }));
            } else if (key === "role") {
              setInputData((prevFields) => ({
                ...prevFields,
                role: trimStr,
              }));
            }
          }} />
        }
      </Box>
    );
  };

  const textFieldView2 = (key: string, value: string, defaultValue: string) => {
    return (
      <TextField InputProps={{
        disableUnderline: isEdiTable ? true : false,
        style: { fontSize: 12, marginTop: 8, color: BLACK, minWidth: 180 }
      }}
        disabled={isEdiTable}
        variant="standard" size="small"
        value={value}
        defaultValue={defaultValue}
        onChange={(text) => {
          const trimStr = text.target.value.trimStart();
          if (key === "first_name") {
            setInputData((prevFields) => ({
              ...prevFields,
              name: trimStr,
            }));
          } else if (key === "last_name") {
            setInputData((prevFields) => ({
              ...prevFields,
              lname: trimStr,
            }));
          }
        }} />
    );
  };

  const dummyView = () => {
    return <Box sx={{ display: "flex", minWidth: 80, height: 5 }} />;
  };

  const roleDisplayView = (inputLabel: any, value: any, items: any) => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: { xs: "92%", sm: "72%", lg: "72%", xl: "72%" },
        justifyContent: 'space-between',
      }}
      >
        <label className={labelStyle}>{inputLabel} :</label>
        <FormControl sx={{
          width: { xs: "80%", sm: "65%", lg: "65%", xl: "65%" },
          borderRadius: 8, mr: "5%",
          minWidth: '200px',
          height: '60%',
          paddingTop: 1
        }}>
          <Select
            style={{
              border: 1,
              height: '100%',
              borderRadius: 8,
              fontSize: 12,
              textAlign: 'start'
            }}
            disabled={isEdiTable}
            value={value}
            onChange={(event: any) => {
              const selectedRole: any = primaryRoleNameList.filter((role: any) => (role === event.target.value))
              primaryRoleList.map((roleData: any) => {
                  if (roleData.name === selectedRole[0]) {
                      setPrimarySelectedRole(roleData.name)
                      setInputData((prevFields) => ({
                        ...prevFields,
                        role: roleData.name,
                      }));
                  }
              })
          }}
          >
            {items.map((item: any) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const capacityDisplayView = (inputLabel: any, value: any, items: any) => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: { xs: "92%", sm: "72%", lg: "72%", xl: "72%" },
        justifyContent: 'space-between',
      }}
      >
        <label className={labelStyle}>{t("YC_CAPACITY")} :</label>
        <FormControl sx={{
          width: { xs: "80%", sm: "65%", lg: "65%", xl: "65%" },
          borderRadius: 8, mr: "5%",
          minWidth: '200px',
          height: '60%',
          paddingTop: 1
        }}>
          <Select
            style={{
              border: 1,
              height: '100%',
              borderRadius: 8,
              fontSize: 12,
              textAlign: 'start'
            }}
            value={value}
            onChange={(event) => {
              const selectedApp: any = applicationNameList.filter((app: any) => (app === event.target.value))
                applicationList.map((appData: any) => {
                    if (appData.application_name === selectedApp[0]) {
                        setSelectedApp(appData.application_name)
                        setInputData((prevFields) => ({
                            ...prevFields,
                            yclickpermission_id: appData.id,
                          }));
                    }
                })
              // setSelectedCapacity(event.target.value);
              // const capacitySelected: any = capacity.filter((capacity: any) => (capacity.name === event.target.value))
              // setInputData((prevFields) => ({
              //   ...prevFields,
              //   permission: capacitySelected[0]?.id,
              // }));
            }}
          >
            {items.map((item: any) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const capacityFeatureView = (features: any) => {
    return (
      features.map((feature: any) => (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: { xs: "80%", sm: "50%", lg: '50%', xl: '50%' },
            justifyContent: 'space-between'
          }}
          >
            <Box sx={{ minWidth: { xs: "40%", sm: "45%", lg: '45%', xl: '45%' } }}></Box>
            <InputLabel sx={{ fontSize: 12, color: BLACK, minWidth: '200px', textAlign: 'start' }}>
              {`• ${feature.value}`}
            </InputLabel>
          </Box>
          {feature.subFeature.length > 0 && (
            capacitySubFeatureView(feature.subFeature)
          )}
        </>))
    )
  };

  const capacitySubFeatureView = (subFeature: any) => {
    return (
      subFeature.map((feature: any) => (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center'
          }}
          >
            <InputLabel sx={{ fontSize: 12, color: BLACK, textAlign: 'start', marginLeft: { xs: "40%", sm: "28%", lg: '28%', xl: '28%' } }}>
              {`• ${feature}`}
            </InputLabel>
          </Box>
        </>))
    )
  }

  const summryView = (user: any) => {
    return(
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            border: 1,
            borderRadius: 2,
            width: { xs: "94%", sm: "94%", lg: '100%', xl: '100%' },
            background: "#F9F9FA",
            p: 2
          }}
        >
          {textFieldView("user_name", t("YC_USERNAME"), inputData.name, `${user.name + " " + user.lname}`)}
          {dummyView()}
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '93%', justifyContent: { sm: 'space-between', lg: 'space-between', xl: 'space-between' } }}>
            <label className={labelStyleForRowTextField}> {t("YC_NAME")}:</label>
            <Box sx={{ width: { xs: '21%', sm:'11%'}}}></Box>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
              width: { xs: "20%", sm: "76.5%", lg: '76.5%', xl: '76.5%' },
              justifyContent: 'space-between',
              minWidth: { xs: '165px', sm: '220px', lg: '220px', xl: '220px' }
            }
            }
            >
              {textFieldView2("first_name", inputData.name, user.name)}
              <Box sx={{ width: '5%' }}></Box>
              {textFieldView2("last_name", inputData.lname, user.lname)}
            </Box>
          </Box>
          {dummyView()}
          {textFieldView("email", t("YC_EMAIL"), inputData.email, user.email)}
          {dummyView()}
          {textFieldView("mobile_number", t("YC_MOBILE_NO"), `${user.contrycode + user.mobile}`, `${'+' + user.contrycode + " " + user.mobile}`)}
          {dummyView()}
          {/* {textFieldView("role", roleCapacity === "Yes" ? t("YC_PRIMARY_ROLE") : t("YC_ROLE"), inputData.role, user.role)} */}
          {roleDisplayView(roleCapacity === "Yes" ? t("YC_PRIMARY_ROLE") : t("YC_ROLE"), inputData.role, primaryRoleNameList)}
          {roleCapacity === "Yes" ? capacityDisplayView(user.name, selectedCapacity, applicationNameList) : null}
          {roleCapacity === "Yes" && capacityFeatureView(capacityFeature)}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {dummyView()}
            <CustomButton
              disabled={false}
              placeHolder={isEdiTable ? t("YC_EDIT") : t("YC_UPDATE")}
              textTransform="none"
              background={isEdiTable ? GREY_2 : BLACK_2 }
              height="35px"
              width={"10%"}
              buttonStyle={{
                HEADER2,
                minWidth: "50px",
              }}
              onClick={() => {
                if (isEdiTable) {
                  setIsEdiTable(false)
                } else {
                  editAdditionalUserDetails(user)
                }
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", minWidth: 80, height: 20 }} />
      </>)  
  }
  const stepInfo = (textOne: any, textTwo: any = "", textThree: any = "", bgColor: any = INFO) => {
    return (
      <StepInformation
        minWidth={"100%"}
        boxStyle={{ marginBottom: "20px" }}
        bgColor={bgColor}
        textColor={WHITE}
        textOne={textOne}
        textTwo={textTwo}
        textThree={textThree}
      />
    )
  }

  const infoViewForNonController = () => {
    return(
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >   
          <StepInformation
                  bgColor={"rgba(15, 92, 208, 0.8)"}
                  minWidth={'100%'}
                  textColor={WHITE}
                  title={t("YC_CONTROLLER_SETUP_5_NON_CONTROLLER_INFO_1")}
                  textOne={t("YC_CONTROLLER_SETUP_5_NON_CONTROLLER_INFO_2")}
                  textTwo={t("YC_CONTROLLER_SETUP_5_NON_CONTROLLER_INFO_3")}
          />
          <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
          <StepInformation
              bgColor={RED}
              minWidth={'100%'}
              textColor={WHITE}
              textOne={t("YC_CONTROLLER_SETUP_5_NON_CONTROLLER_RED_INFO_1")}
              textTwo={t("YC_CONTROLLER_SETUP_5_NON_CONTROLLER_RED_INFO_2")}
          />
      </Box>
    )
  }
  return (
    <Box
      sx={{
        m: 3,
        width: "97%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography className={titleText} sx={{ display: "flex" }}>
        {t("YC_CONTROLLER_STEP_5_TITLE")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "space-between",
          mt: 3
        }}
      >
        <Typography className={titleText} sx={{ display: "flex" }}>
          {t("YC_USER_ROLE_AND_NAME")}
        </Typography>
        {dummyView()}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, width: "100%" }}>
          {renderSelect("role_name", controllerRoleName, controllerOfAccount, roleName)}
          <Box sx={{ display: "flex", minWidth: 20, height: 5 }} />
          {selectionView(
            "role_capacity",
            t("YC_USER_ROLE_CAPACITY_QUESTION"),
            ["Yes", "No"],
            roleCapacity
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            width: "100%"
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: "100%"
              }}
            >
              <Typography className={titleText} sx={{ display: "flex" }}>
                {t("YC_USER_DETAILS")}
              </Typography>
              {summryView(selectedUser)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", 
                flexDirection: "column",
                ml: { xs: 0, sm: 0, lg: 10, xl: 10 },
                mt: 3.5,
                width: { xs: "65%", sm: "95%", lg: "75%", xl: "88%" }}}>
                {props.isUserController === "Yes" ? 
                stepInfo(t("YC_CONTROLLER_SETUP_5_INFO_1"), t("YC_CONTROLLER_SETUP_5_INFO_2"), 
                  t("YC_CONTROLLER_SETUP_5_INFO_3"), INFO)
                 : infoViewForNonController()}
            
            {/* {stepInfo('As Controller you can go to User Access section of your universal dashboard to add, update or remove a user. You can add, edit and remove role and capacity and access to application you are signing up to within the same section..', '', RED)} */}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("controllerStep", "3")
            props.goToNext(3, "", "", true)
          }}
        />
        <CustomButton
          disabled={false}
          placeHolder={t("YC_COMPLETE_SETUP")}
          textTransform="none"
          background={BLUE}
          height="40px"
          width={"20%"}
          marginRight={10}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            CompleteSetupAction();
          }}
        />
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3,
        }}
      >
        <CustomButton
          disabled={false}
          placeHolder={t("YC_COMPLETE_SETUP")}
          textTransform="none"
          background={BLUE}
          height="40px"
          width={"20%"}
          marginRight={10}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            CompleteSetupAction();
          }}
        />
      </Box> */}
    </Box>
  );
}
export default ControllerProcessFive;
