import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
  } from "@mui/material";
  import CustomButton from "components/custom-button/custom-button";
  import CustomSelect from "components/custom-select/custom-select";
  import { BLACK, BLUE, GREY, WHITE } from "utils/colors";
  import StepInformation from "../dashboard-reusables/step-information";
  import { HEADER2 } from "utils/fonts";
  import { makeStyles } from "@mui/styles";
  import React, { useEffect } from "react";
  import SuccessIcon from "../../../assets/success.png";
  import CustomTitleSelect from "components/custom-title-select/custom-title-select";
  import HomeApi from "services/HomeAPi";
  import Notification from "views/Notifications/Notification";
  import ErrorExclamation from "../../../assets/exclamation-mark.png";
  import { useTranslation } from "react-i18next";

  const useStyles = makeStyles(() => ({
    titleText: {
      color: BLACK,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 1,
    },
  }));
  
  function BusinessProcessTwo(props: any) {
    const { titleText } = useStyles();
    const [isNextStepDisable, setIsNextStepDisable] = React.useState(true)
   
    const [countryList, setCountryList] = React.useState([])
    const [countryName, setCountryName] = React.useState([]);
   
    const [regionList, setRegionList] = React.useState([])
    const [regionName, setRegionName] = React.useState([]);
   
    const [cityList, setCityList] = React.useState([])
    const [cityName, setCityName] = React.useState([]);
    
    const [countryId, setCountryId] = React.useState(0)
    const [regionId, setRegionId] = React.useState(0)
    const [cityId, setCityId] = React.useState(0)

    const { t } = useTranslation()

    const [inputData, setInputData] = React.useState({
      country: "",
      region: "",
      city: "",
      address: "",
      postalCode: ""
    });

    const getUserId = () => {
      let userId;
      const loginUserDetails = localStorage.getItem("user_Details")
      if (loginUserDetails){
        const details = JSON.parse(loginUserDetails)
        userId = details.id
      }
      return userId
    }
  
    const getCountryList = async () => {
      const response = await HomeApi.getCountryList();
      if (response.status === true) {
        setCountryList(response.data)
        let tempArray: any = [];
        response.data.map((element: any) => {
          tempArray.push(element.name)
        });
        setCountryName(tempArray)
      }
    }

    const regionData = async (countryCode: string) => {
      const response = await HomeApi.getRegionData(countryCode);
      if (response.status === true) {
        setRegionList(response.data)
        let tempArray: any = [];
        response.data.map((element: any) => {
          tempArray.push(element.name)
        });
        setRegionName(tempArray)
        setRegionList(response.data)
      } else {
        Notification.notifyError(response.message)
      }
    }

    const cityData = async (regionCode: string) => {
      const response = await HomeApi.getCityData(regionCode);
      if (response.status === true) {
        setCityList(response.data)
        let tempArray: any = [];
        response.data.map((element: any) => {
          tempArray.push(element.name)
        });
        setCityName(tempArray)
      } else {
        Notification.notifyError(response.message)
      }
    }
    

    useEffect(() => {
      getCountryList();
      getBusinessSetupData()
    },[])

    useEffect(() => {
        validateForNextStep()
      }, [inputData.country, inputData.region, inputData.city, inputData.address])

  const validateForNextStep = () => {
    if (inputData.country && inputData.region && inputData.city && inputData.address) {
        setIsNextStepDisable(false)
    } else {
        setIsNextStepDisable(true)
    }
  }  

  const nextStep = async (companyDetails: any) => {
    localStorage.setItem("businessStep", "3")
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    const payload = {
      country: countryId,
      region: regionId,
      cities: cityId,
      address: inputData.address,
      postal_code: inputData.postalCode,
      setup_count: 2,
      step_count: 3
  };
  
    const response = await HomeApi.uploadStepTwoBusinessSetup(payload, userId);
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      props.isFromDashBoard ?  props.setIsShowBusinessStepTwoModal(false) : props.goToNext(3)
      // props.goToNext(3);
      emptyData();
    } else {
      Notification.notifyError(response.message)
    }
  }; 
  
  
  
  const emptyData = () => {
    setInputData({
        country: "",
        region: "",
        city: "",
        address: "",
        postalCode: ""
    })
    
  }
  
  const getBusinessSetupData = async () => {
    const response = await HomeApi.getBusinessSetupData(getUserId())
    if(response.status === true){
      setCountryId(response.data.country)
      setRegionId(response.data.region)
      setCityId(response.data.cities)

      setInputData({
        country:response.data.country_name,
        region:response.data.region_name,
        city:response.data.city_name,
        address: response.data.address,
        postalCode: response.data.postal_code
      })

    //   countryList.map((country: any) => {
    //     if (country.id === response.data.country){
    //       regionData(country.id)
    //       setCountryId(country.id)
    //       setInputData((prevFields) => ({
    //         ...prevFields,
    //         country: country.name,
    //       }))
    //     }})
        
    //     regionList.map((region: any) => {
    //       if (Number(region.id) === Number(response.data.region)){
    //         cityData(region.id)
    //         setRegionId(region.id)
    //         setInputData((prevFields) => ({
    //           ...prevFields,
    //           region: region.name,
    //         }))
    //       }})

    //       cityList.map((city: any) => {
    //         if (Number(city.id) === Number(response.data.cities)){
    //           setCityId(city.id)
    //           setInputData((prevFields) => ({
    //             ...prevFields,
    //             city: city.name,
    //           }))
    //         }})

    //         setInputData((prevFields) => ({
    //           ...prevFields,
    //           address: response.data.address,
    //           postalCode: response.data.postal_code
    //         }))
    }}

    useEffect(() => {
      countryList.map((country: any) => {
        if (Number(country.id) === Number(countryId)){
          setInputData((prevFields) => ({
            ...prevFields,
            country: country.name,
          }))
        }})

        regionList.map((region: any) => {
          if (Number(region.id) === Number(regionId)){
            setInputData((prevFields) => ({
              ...prevFields,
              region: region.name,
            }))
          }})

          cityList.map((city: any) => {
            if (Number(city.id) === Number(cityId)){
              setInputData((prevFields) => ({
                ...prevFields,
                city: city.name,
              }))
            }})
    }, [countryId, regionId, cityId])

    const renderIcon = (iconName: any) => {
      return (
          <img
            src={iconName}
            height={iconName === ErrorExclamation ? 35 : 22}
            alt={"icon"}
          />
      );
    };
  
    const textFieldView = (key: string, label: string = "", value: string) => {
      return (
        <Box sx={{width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" }}}>
          <FormControl
            sx={{
              mb: 1,
              width: key === "address" ? { xs: "100%", sm: "230%", lg: "230%", xl: "230%" } : "100%",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
              background: WHITE,
            }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput
              endAdornment={
                value?.length === 0 ?  (<InputAdornment position="end">
                {renderIcon(ErrorExclamation)}
              </InputAdornment>) : (<InputAdornment position="end">
                {renderIcon(SuccessIcon)}
              </InputAdornment>)
              }
              label={label}
              value={value}
              disabled={false}
              onChange={(text) => {
                const trimStr = text.target.value.trimStart();
                if (key === "town") {
                    setInputData((prevFields) => ({
                    ...prevFields,
                    town: trimStr,
                    }))
                } else if (key === "address") {
                  setInputData((prevFields) => ({
                    ...prevFields,
                    address: trimStr,
                  }));
                } else if (key === "postal_code") {
                  setInputData((prevFields) => ({
                    ...prevFields,
                    postalCode: trimStr,
                  }));
                }
              }}
            />
          </FormControl>
        </Box>
      );
    };
  
    const dummyView = () => {
      return <Box sx={{ display: "flex", minWidth: 80, height: 10 }} />;
    };
  
    const selectionView = (
        key: any,
        items: any,
        value: any,
        label: any,
        canSelect = true) => {
        return(
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: "110%", sm: "89%", lg: "89%", xl: "89%"}
          }}
        >
                <CustomTitleSelect
                title={label}
                value={value}
                onChange={(event: any) => {
                  if (key === "country") {
                    countryList.map((country: any) => {
                        if (country.name === event.target.value){
                          regionData(country.id)
                          setCountryId(country.id)
                        }})
                      setInputData((prevFields) => ({
                        ...prevFields,
                        country: event.target.value,
                      }))
                  } else if (key === "region") {
                    regionList.map((region: any) => {
                      if (region.name
                        === event.target.value){
                        cityData(region.id)
                        setRegionId(region.id)
                      }})
                  setInputData((prevFields) => ({
                      ...prevFields,
                      region: event.target.value,
                  }))
                  } else if (key === "city") {
                    cityList.map((city: any) => {
                      if (city.name
                        === event.target.value){
                        setCityId(city.id)
                      }})
                    setInputData((prevFields) => ({
                        ...prevFields,
                        city: event.target.value,
                    }))
                    }
              }}
                items={items}
                canSelect={canSelect}
                keepTextGreen={true}
              />
            </Box>
        )
    }
  
    return (
      <Box
        sx={{
          m: 3,
          pr: 5,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
          {t("YC_BUSINESS_SETUP_STEP_TWO_TITLE")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: "column",
              width: "100%",
              minWidth: { xs: "100%", sm: "50%", lg: "50%", xl: "50%" }
            }}
          >
            {selectionView("country", countryName, inputData.country, t("YC_COUNTRY"), true)}
            {dummyView()}
            {selectionView("region", regionName, inputData.region, t("YC_REGION"), true)}
            {dummyView()}
            {selectionView("city", cityName, inputData.city, t("YC_CITY_VILLAGE"), true)}
            {dummyView()}
            {dummyView()}
            {textFieldView(
              "postal_code",
              "Postal Code",
              inputData.postalCode
            )}
            {textFieldView(
              "address",
              t("YC_ADDRESS"),
              inputData.address
            )}
            <Box sx={{ display: "flex", minWidth: 80, height: 15 }} />
          </Box>
          <StepInformation
            boxStyle={{ marginTop: "10px" }}
            width={"95%"}
            minWidth={"43%"}
            maxWidth={"47%"}
            bgColor={"rgba(15, 92, 208, 0.8)"}
            textColor={WHITE}
            textOne={t("YC_BUSINESS_SETUP_STEP_TWO_INFO_1")}
            textTwo={t("YC_BUSINESS_SETUP_STEP_TWO_INFO_2")}
          />
        </Box>
        <div style={{marginTop: 10}}/>
        <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        {!props.isFromDashBoard && (<CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("businessStep", "1")
            props.goToNext(1, true)
          }}
        />
        )}
        <CustomButton
            disabled={isNextStepDisable}
            placeHolder={!props.isFromDashBoard ? t("YC_NEXT_STEP") : t("YC_SUBMIT")}
            textTransform="none"
            background={isNextStepDisable ? GREY : BLUE}
            height="40px"
            width={"20%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={() => {
              nextStep(inputData)
            }}
          />
      </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "flex-end",
            width: "98%",
            mb: 3,
            mt: 3,
          }}
        >
          <CustomButton
            disabled={isNextStepDisable}
            placeHolder={t("YC_NEXT_STEP")}
            textTransform="none"
            background={isNextStepDisable ? GREY : BLUE}
            height="40px"
            width={"20%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={() => {
              nextStep(inputData)
            }}
          />
        </Box> */}
      </Box>
    );
  }
  export default BusinessProcessTwo;
  