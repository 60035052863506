export const YELLOW = '#FFD703';
export const BLACK = '#000000';
export const GREY = '#D7D7D7';
export const WHITE = '#fff';
export const ERROR = '#CA1F1F';
export const LINK = '#0F5CD0';
export const RED = '#b50404';
export const GREEN = '#179603';
export const INFO = 'rgba(15, 92, 208, 0.8)';
export const PINK = '#C80C80CC';
export const DARKGREY = '#ABB5C0';
export const DARK_GRAY = '#ABB5C0';
export const LIGHT_RED = '#E2B0B0'
export const BLACK_2 = '#1D2327';
export const GREY_2 = '#A9A9A9';
export const BLUE = '#0F5CD0';
export const BLUE_2 = "#0F5CD033";
export const PINK_2 = "#fa348a";
export const PINK_3 = "#ebb9b9";
export const BLUE_3 = "#c1d6f5"
