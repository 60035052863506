import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import PasswordField from "components/password-field/password-field";
import PasswordStrength from "components/password-field/password-strength-indicator";
import React, { useEffect } from "react";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import { getStrength, validatePassword } from "utils/helper";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
// import { YC_ACCOUNT_PASSWORD_TITLE, YC_ACCOUNT_SETUP_ADMINISTRATOR_TITLE, YC_ACCOUNT_SETUP_CONTROLLER_TITLE, 
//   YC_ACCOUNT_TYPE_TITLE, YC_BUSINESS, YC_CONFIRM_PASSWORD, YC_CONTROLLER_PASSWORD, 
//   YC_CONTROLLER_SETUP_BUSINESS_INFO_1, YC_CONTROLLER_SETUP_BUSINESS_INFO_2, YC_CONTROLLER_SETUP_BUSINESS_INFO_3, 
//   YC_CONTROLLER_SETUP_BUSINESS_INFO_RED_1, YC_CONTROLLER_SETUP_BUSINESS_NONCONTROLLER_PASSWORD_INFO_RED_1, 
//   YC_CONTROLLER_SETUP_BUSINESS_NONCONTROLLER_PASSWORD_INFO_RED_2, YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_1, 
//   YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_2, YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_3, YC_CONTROLLER_SETUP_INFO_1,
//   YC_CONTROLLER_SETUP_INFO_2, YC_CONTROLLER_SETUP_PUBLIC_INFO_1, YC_CONTROLLER_SETUP_PUBLIC_INFO_2, 
//   YC_CONTROLLER_SETUP_PUBLIC_INFO_RED_1, YC_NEXT_STEP, YC_NO, YC_NON_CONTROLLER_PASSWORD,
//   YC_PASSWORD_NOT_MATCH, YC_PUBLIC, YC_THERE_ARE_TWO_TYPE_YC_ACCOUNTS, YC_WHICH_TYPE_ACCOUNT_SETUP, 
//   YC_WILL_YOU_BE_CONTROLLER_OF_ACCOUNT, YC_YES } from "utils/strings";
import { BorderColor, BorderStyle, Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "7.2px",
    width: "97.5%",
    background: "#FFFFFF",
  },
  titleText: {
    display: "flex",
    color: BLACK,
    fontSize: 24,
    fontWeight: "700",
    marginTop: 1,
  },
}));

function ControllerProcessOne(props: any) {
  const [controllerOfAccount, setControllerOfAccount] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [accountTypeId, setAccountTypeId] = React.useState(0);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordStrength, setPasswordStrength] = React.useState(10);
  const [isPasswordNotMatch, setIsPasswordNotMatch] = React.useState(false);

  const [isValidData, setIsValidData] = React.useState(false);
  const [accountList, setAccountList] = React.useState([])
  const [accountNameList, setAccountNameList] = React.useState([])

  const { t } = useTranslation()

  const validatePassword = () => {
    if (password && confirmPassword) {
      if (passwordStrength >= 80 && password === confirmPassword) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getAccountList = async () => {
    const response = await HomeApi.getAccountList();
    if (response.status === true) {
      setAccountList(response.data)
        let tempArray: any = [];
        response.data.map((accountData: any) => {
        tempArray.push(accountData.acc_name)
        });
        setAccountNameList(tempArray)
    }
 }

  useEffect(() => {
    getControllerSetupData()
    getAccountList()
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setControllerOfAccount(event.target.value);
  };

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const getControllerSetupData = async () => {
    const response = await HomeApi.getControllerSetupData(getUserId())
    if(response.status === true){
      accountList.map((accountData: any) => {
        if (Number(response.data.typeofaccount_id) === accountData.id){
          setAccountType(accountData.acc_name)
        }
        })
      // setAccountTypeId(Number(response.data.typeofaccount))
      setAccountTypeId(Number(response.data.typeofaccount_id))
      setControllerOfAccount(response.data.controller)
    }
  }

  useEffect(() => {
    accountList.map((accountData: any) => {
    if (Number(accountTypeId) === accountData.id){
      setAccountType(accountData.acc_name)
    }
    })
  }, [accountTypeId, accountType])

  const handleAccountTypeChange = (event: SelectChangeEvent) => {
    setAccountType(event.target.value);
    const selectedAccount: any = accountNameList.filter((name: any) => (name === event.target.value))
              accountList.map((accountTypeData: any) => {
                if (accountTypeData.acc_name === selectedAccount[0]) {
                  setAccountTypeId(accountTypeData.id)
                }
              })
    event.target.value === t("YC_PUBLIC") ? setControllerOfAccount("Yes") : setControllerOfAccount("")
  };

  const isCreatePasswordValid = () => {
    if (
      password !== "" &&
      confirmPassword !== "" &&
      password !== confirmPassword
    ) {
      setIsPasswordNotMatch(true);
    } else {
      setIsPasswordNotMatch(false);
    }
  };

  const confirmationControllerAccount = async () => {
    const payload = {
      controller: controllerOfAccount,
      account_type: accountTypeId,
      password: password,
      setup_count: 1,
      step_count: 2
    };

    const response = await HomeApi.confirmationControllerAccount(payload);
  };

  const isValid = () => {
    if (password !== "" && confirmPassword !== "" && controllerOfAccount !== "" && (password === confirmPassword) ) {
      setIsValidData(true);
    } else {
      setIsValidData(false);
    }
  };

  useEffect(() => {
    isCreatePasswordValid()
    isValid()
  }, [password, confirmPassword])

  const { titleText } = useStyles();

  const accountTypeInformationView = () => {
    return (
      <StepInformation
        bgColor={"rgba(15, 92, 208, 0.8)"}
        textColor={WHITE}
        title={t("YC_THERE_ARE_TWO_TYPE_YC_ACCOUNTS")}
        textOne={t("YC_CONTROLLER_SETUP_INFO_1")}
        textTwo={t("YC_CONTROLLER_SETUP_INFO_2")}
      />
    )
  }

  const publicInformationView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <StepInformation
          bgColor={"rgba(15, 92, 208, 0.8)"}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_PUBLIC_INFO_1")}
          textTwo={t("YC_CONTROLLER_SETUP_PUBLIC_INFO_2")}
        />
        <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
        <StepInformation
          bgColor={RED}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_PUBLIC_INFO_RED_1")}
        />
      </Box>
    )
  }

  const businessInformationView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <StepInformation
          bgColor={"rgba(15, 92, 208, 0.8)"}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_BUSINESS_INFO_1")}
          textTwo={t("YC_CONTROLLER_SETUP_BUSINESS_INFO_2")}
          textThree={t("YC_CONTROLLER_SETUP_BUSINESS_INFO_3")}
        />
        <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
        <StepInformation
          bgColor={RED}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_BUSINESS_INFO_RED_1")}
        />
      </Box>
    )
  }

  const businessInformationPasswordView = () => {
    return (
      <StepInformation
        bgColor={"rgba(15, 92, 208, 0.8)"}
        textColor={WHITE}
        title={t("YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_1")}
        textOne={t("YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_2")}
        textTwo={t("YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_3")}
      />
    )
  }

  const businessInformationNonControllerView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <StepInformation
          bgColor={"rgba(15, 92, 208, 0.8)"}
          minWidth={'100%'}
          textColor={WHITE}
          title={t("YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_1")}
          textOne={t("YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_2")}
          textTwo={t("YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_3")}
        />
        <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
        <StepInformation
          bgColor={RED}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_CONTROLLER_SETUP_BUSINESS_NONCONTROLLER_PASSWORD_INFO_RED_1")}
          textTwo={t("YC_CONTROLLER_SETUP_BUSINESS_NONCONTROLLER_PASSWORD_INFO_RED_2")}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        m: 3,
        width: "97%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography className={titleText}>
        {accountType.length === 0 ? t("YC_ACCOUNT_TYPE_TITLE") : accountType === t("YC_PUBLIC") ?
          t("YC_ACCOUNT_SETUP_CONTROLLER_TITLE") : controllerOfAccount.length === 0 ?
          t("YC_ACCOUNT_SETUP_ADMINISTRATOR_TITLE") : t("YC_ACCOUNT_PASSWORD_TITLE")}
        {/* {controllerOfAccount === "No"
          ? "Step One Of Setup Process - Additional User"
          : "Confirmation: Controller And User Setup"} */}
      </Typography>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mr: 2,
            mt: 10,
          }}
        >
          <Box sx={{ flexDirection: "column", width: "100%", minWidth: { xs: '100%', sm: 0, lg: 0, xl: 0 } }}>
            <CustomTitleSelect
              title={t("YC_WHICH_TYPE_ACCOUNT_SETUP")}
              value={accountType}
              onChange={handleAccountTypeChange}
              items={accountNameList}//{[t("YC_PUBLIC"), t("YC_BUSINESS")]}
              canSelect={true}
              isFromControllerStepOne={true}
            />
            <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
            {/* {accountType !== t("YC_PUBLIC") */}
            {accountTypeId === 2 &&
              <CustomTitleSelect
                title={t("YC_WILL_YOU_BE_CONTROLLER_OF_ACCOUNT")}
                value={controllerOfAccount}
                onChange={handleChange}
                items={["Yes", "No"]}
                canSelect={true}
              />
            }
            <Box
              sx={{
                mt: 1,
                display: "flex",
                width: "100%",
                flexDirection: "column"
              }}
            >
              {(controllerOfAccount && accountType !== "") && (
                <>
                  <PasswordField
                    label={
                      controllerOfAccount === "Yes"
                        ? t("YC_CONTROLLER_PASSWORD")
                        : t("YC_NON_CONTROLLER_PASSWORD")
                    }
                    value={password}
                    fieldStyle={{ marginTop: 30 }}
                    maxWidth={'70%'}
                    width={"100px"}
                    onChange={(text: any) => {
                      setPasswordStrength(getStrength(text.target.value));
                      setPassword(text.target.value);
                      isCreatePasswordValid();
                    }}
                  />
                  <PasswordStrength
                    strength={passwordStrength}
                    password={password}
                  />
                  <PasswordField
                    label={t("YC_CONFIRM_PASSWORD")}
                    value={confirmPassword}
                    fieldStyle={{ marginTop: 10 }}
                    width={"100px"}
                    maxWidth={'70%'}
                    onChange={(text: any) => {
                      setConfirmPassword(text.target.value);
                      isCreatePasswordValid();
                    }}
                  />
                  {
                    isPasswordNotMatch && (
                      <InputLabel style={{ color: RED, textAlign: 'start', fontSize: 12, width: '70%' }}>{t("YC_PASSWORD_NOT_MATCH")}</InputLabel>
                    )
                  }
                </>
              )}
            </Box>
          </Box>
          {accountType.length === 0 ? accountTypeInformationView()
            : accountType === t("YC_PUBLIC") ? publicInformationView()
              : controllerOfAccount.length === 0 ? businessInformationView()
                : controllerOfAccount === "Yes" ? businessInformationPasswordView() : businessInformationNonControllerView()
          }
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "flex-end",
            width: { xs: "93%", sm: "93%", lg: "98%", xl: "98%" },
            mb: 3,
            mt: 3,
          }}
        >
          <CustomButton
            disabled={isValidData ? false : true}
            placeHolder={t("YC_NEXT_STEP")}
            textTransform="none"
            background={
              isValidData ? BLUE : GREY
            }
            height="40px"
            width={"20%"}
            borderRadius="8px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={() => {
              localStorage.setItem("controllerStep", "2")
              props.goToNext(controllerOfAccount);
              confirmationControllerAccount()
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
export default ControllerProcessOne;
