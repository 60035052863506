import React from "react";
// const MultiCheckboxComponent = ({ list }) => {
//     return (
//         <div>
//             {list?.map((item: any, index: any) => (
//                 <div>
//                     <input
//                         style={{ fontStyle: "normal" }}
//                         type="checkbox"
//                         id={item.focus}
//                         value={item.focus}
//                         checked={item.isAdded}
//                         onChange={(e) => onAddingItem(e, item, index)}
//                     />
//                     <label className={classes.label1} htmlFor={item.focus}>
//                         {item.focus}
//                     </label>
//                 </div>
//             ))}
//         </div>
//     );
// };
function MultiCheckboxComponent({list, handleOnChange} : any) {

    return (
                <div>
                    {list?.map((item: any, index: any) => (
                        <div>
                            <input
                                style={{ fontStyle: "normal" }}
                                type="checkbox"
                                id={item.id}
                                value={item.focus}
                                checked={item.isAdded}
                                onChange={(e) => handleOnChange(e, item, index)}
                            />
                            <label >
                                {item.focus}
                            </label>
                        </div>
                    ))}
                </div>
            );
}
export default MultiCheckboxComponent;