import * as React from "react";
import {
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BillingIcon from "../../assets/Billing_fill.png";
import ApplyIcon from "../../assets/Apply_fill.png";
import DocumentationIcon from "../../assets/Folder_alt_fill.png";
import SettingsIcon from "../../assets/Settings_fill.png";
import SupportIcon from "../../assets/Support_fill.png";
import AppToolsIcon from "../../assets/Stackframe_fill.png";
import NotificationIcon from "../../assets/Notification_fill.png";
import UserAccessIcon from "../../assets/User_access_fill.png";
import TransactionsIcon from "../../assets/Transactions_fill.png";
import TasksListsIcon from "../../assets/Task_list_fill.png";
import EditorialIcon from "../../assets/Editorial_fill.png";
import WidgetsIcon from "../../assets/Widgets_fill.png";
import OverviewIcon from "../../assets/Overview_Graph.png";
import CustomiseMenuIcon from "../../assets/Customise_menu_Icon.png";
import { useNavigate } from "react-router-dom";
import PopUpModal from "components/pop-up-view/popup-modal";
import { useTranslation } from "react-i18next";


const UseStyles = makeStyles(() => ({
  cellView: {
    color: YELLOW,
    background: BLACK,
  },
  cellText: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  dividerStyle: {
    borderColor: WHITE,
    borderWidth: 0.5,
    opacity: 1,
  },

}));

const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function SideNav(props: Props) {
  const {
    cellView,
    cellText,
    dividerStyle,
  } = UseStyles();
  const [openDashboard, setOpenDashboard] = React.useState(false);
  const [openAppTools, setOpenAppTools] = React.useState(false);
  const [openFunctions, setOpenFunctions] = React.useState(false);
  const [userRole, setUserRole] = React.useState()
  const [isOpen, setIsOpen] = React.useState(false)
  const navigate = useNavigate();
  const { t } = useTranslation()

  const getUserDetails = () => {
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      setUserRole(details.role)
    }
  }

  React.useEffect(() => {
    getUserDetails()
  },[])

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const renderItem = (itemName: string, icon: any = null) => {
    return (
      <Box>
        <ListItemButton
          className={cellView}
          sx={icon ? null : { ml: 5 }}
          onClick={() => {
            if(itemName === "Logout"){
              setIsOpen(true)
              // localStorage.clear();
              // navigate("/")
            }
           }}
        >
          {icon && (
            <ListItemIcon>
              <img src={icon} height={30} alt={"icons"} />
            </ListItemIcon>
          )}
          <Box className={cellText}>
            <Typography>{itemName}</Typography>
          </Box>
        </ListItemButton>
      </Box>
    );
  };

  const renderNestedItem = (
    itemName: string,
    isNested: boolean = false,
    nestedItems: any,
    isOpen: boolean = false,
    icon: any = null
  ) => {
    return (
      <Box>
        <ListItemButton
          className={cellView}
          sx={icon ? null : { ml: 5 }}
          disabled={userRole === "controller" ? false : true}
          onClick={() => {
            if (itemName === "DASHBOARD") {
              setOpenDashboard(!openDashboard);
              navigate("/incomplete-dashboard");
            }
            else if (itemName === "APPS-TOOLS") setOpenAppTools(!openAppTools);
            else if (itemName === "FUNCTIONS") setOpenFunctions(!openFunctions);
          }}
        >
          {icon && (
            <ListItemIcon>
              <img src={icon} height={40} alt={"icons"} />
            </ListItemIcon>
          )}
          <Box className={cellText}>
            <Typography>{itemName}</Typography>
            {isNested ? (
              isOpen ? (
                <ExpandLess htmlColor="white" />
              ) : (
                <ExpandMore htmlColor="white" />
              )
            ) : null}
          </Box>
        </ListItemButton>
        {isNested &&
          nestedItems.map((item: any) => (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  className={cellView}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    if (item.name === "User Access") {
                      navigate("/user-access");
                    }
                    else if (item.name === "Customise") {
                      navigate("/dashboard-commonUser");
                    }
                  }}
                >
                  {item.icon && (
                    <ListItemIcon>
                      <img src={item.icon} height={25} alt={"icons"} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    sx={item.icon ? null : { ml: 7 }}
                    primary={item.name}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          ))}
        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 2 }}
        />
      </Box>
    );
  };


  const sidePanel = (
    <div>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: BLACK }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {renderNestedItem(
          "DASHBOARD",
          true,
          [
            { name: "Overview", icon: OverviewIcon },
            { name: "Customise", icon: CustomiseMenuIcon }
          ],
          openDashboard
        )}
        {renderNestedItem(
          "APPS-TOOLS",
          true,
          [{ name: "Directory", icon: null }],
          openAppTools,
          AppToolsIcon
        )}
        {renderNestedItem(
          "FUNCTIONS",
          true,
          [
            { name: "Notifications", icon: NotificationIcon },
            { name: "User Access", icon: UserAccessIcon },
            { name: "Transactions", icon: TransactionsIcon },
            { name: "Tasks List", icon: TasksListsIcon },
            { name: "Editorial", icon: EditorialIcon },
            { name: "Widgets", icon: WidgetsIcon },
            { name: "Others", icon: null },
          ],
          openFunctions
        )}
        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 2 }}
        />
        {renderItem("ADMINISTRATION")}
        {renderItem("Billing", BillingIcon)}
        {renderItem("Apply", ApplyIcon)}
        {renderItem("Documentation", DocumentationIcon)}
        {renderItem("Settings", SettingsIcon)}
        {renderItem("Support", SupportIcon)}
        {renderItem("Logout", SupportIcon)}
        <ListItemButton>
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText primary="Drafts" />
        </ListItemButton>
      </List>
    </div>
  );

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box
      sx={{
        marginBottom: "40px",
        height: "100%",
        flex: 1,
      }}
    >
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth } }}
        aria-label="mailbox folders"
      >

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "88%",
              mt: 7,
              background: BLACK,
            },
          }}
          open
        >
          {sidePanel}
        </Drawer>
      </Box>
      <Modal
              open={isOpen}
              onClose={togglePopup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
              <PopUpModal
                title={"Logout"}
                description={"By clicking yes, you will get logout from the application"}
                handleClose={togglePopup}
                firstbuttonName={t("YC_YES")}
                secondbuttonName={t("YC_NO")}
                firstbuttonAction={() => {
                    localStorage.clear();
                    navigate("/")
                }}
                secondbuttonAction={() => {
                    togglePopup()
                }}
      />
              </div>
            </Modal>
    </Box>
  );
};