import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, DARKGREY, RED } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { validateEmail } from "utils/helper";
import Notification from "views/Notifications/Notification";

const AddUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    }

}))
function AddUserDialog(props: any) {
    const { boxView } = AddUserDialogStyle();
    const { t } = useTranslation()

    const [controllerOfAccount, setControllerOfAccount] = React.useState("");
    const [emailExistingUser, setEmailExistingUser] = React.useState("");
    const [userNotFound, setUserNotFound] = React.useState(false);
    const [updateUserRole, setUpdateUserRole] = React.useState("");
    const [newUserRole, setNewUserRole] = React.useState("");
    const [isValidData, setIsValidData] = React.useState(false)
    const [isValidEmail, setIsValidEmail] = React.useState({value: true, error: ""})

    const [inputData, setInputData] = React.useState({
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        displayMobileNo: ""
    });
    // const [roleList, setRoleList] = React.useState([t("YC_ADMIN"), t("YC_HEAD_OF_DEPARTMENT"), t("YC_EDITOR_IN_CHIEF"), t("YC_EDITORIAL"), t("YC_SALES_MANAGER"), t("YC_SALES_REP")])
    
    const isValid = () => {
        if (inputData.email === "") {
          setIsValidData(false);
          return false;
        } else {
            if (isValidEmail.value){
              setIsValidData(true);
            } else {
              setIsValidData(false);
            }
        }
      };

    const handleChange = (event: SelectChangeEvent) => {
        setControllerOfAccount(event.target.value);
    };

    const updateExistingUserRole = async () => {
        const payload = {
            email: emailExistingUser,
            role: updateUserRole
        };
    
        const response = await HomeApi.updateExistingUserRole(payload);
        if (response.status === true) {
            setUserNotFound(false)
            Notification.notifySuccess(response.message)
          } else {
            Notification.notifyError(response.message)
            setUserNotFound(true)
          }
    };

    const addNewUser = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        const payload = {
            fname: inputData.firstName,
            lname: inputData.lastName,
            role: newUserRole,
            email: inputData.email ,
            mobile: inputData.mobile
        };

        const response = await HomeApi.addNewUser(payload, userId);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            props.setIsNewUserAdded(true)
            clearAdditionalUserData();
        } else {
            Notification.notifyError(response.message)
        }
    };

    
      const clearAdditionalUserData = () => {
        setInputData({
            userName: "",
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            displayMobileNo: ""
        })
        setNewUserRole("")
        setIsValidData(false)
      }

      useEffect(() => {
        isValid()
      }, [inputData.email]);

    return (
        <Box className={boxView}>
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
            }}>
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} gutterBottom>{t("YC_CHANGE_EXISTING_USER_ROLE")}</Typography>

                    <Typography variant="caption" >{t("YC_ADD_USER_ALREADY_REGISTER_ANOTHER_APPLICATION")}</Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_USER")}</Typography>
                    <Box sx={{ marginRight: "25%", boxShadow: 4, width: "45%" }}>
                        <TextField style={{
                            fontStyle: "italic",
                            width: "100%"
                        }} id="Type Email or Username" label={t("YC_TYPE_EMAIL_USERNAME")} variant="outlined"
                            size="small" 
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setEmailExistingUser(trimStr);
                            }}/>
                    </Box>
                </Box>

                {userNotFound && (
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: "10px"
                    }}>
                        <Typography variant="caption"> {t("YC_USER")}   </Typography>
                        <Box sx={{ marginRight: "25%", boxShadow: 4, width: "45%" }}>
                            <TextField style={{ fontStyle: "italic", border: "1px solid red", width: "100%"}}
                                disabled={true}
                                InputLabelProps={{
                                    style: {
                                      color: RED,
                                    } }}
                                    InputProps={{ sx: { borderRadius: 0 } }}
                                id="User not found" label={t("YC_USER_NOT_FOUND")} variant="outlined" size="small" 
                            />
                        </Box>
                    </Box>
                )}

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"

                }}>
                    <Typography variant="caption"> {t("YC_ROLE")} </Typography>
                    <Box sx={{ marginRight: "25%", height: 40, boxShadow: 4}}>
                        <Select
                            sx={{ height: 40 }}
                            value={updateUserRole}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => {
                                const selectedRole: any = props.roleNameList.filter((role: any) => (role === event.target.value))
                                props.roleList.map((roleData: any) => {
                                    if (roleData.doc === selectedRole[0]) {
                                        setUpdateUserRole(roleData.doc)
                                    }
                                })
                            }}
                            placeholder={t("YC_ADMINISTRATOR")}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>{t("YC_ADMINISTRATOR")}</>;
                                }
                                return selected;
                            }}
                        >
                            {props.roleNameList.map((item: any) => {
                            return (
                                <MenuItem
                                value={item}
                                >
                                {item}
                                </MenuItem>
                            );
                            })}
                        </Select>
                    </Box>
                </Box>
                <Box sx={{ float: "right" }}>
                    <CustomButton
                        placeHolder={t("YC_UPDATE_USER")}
                        textTransform="none"
                        background={BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => updateExistingUserRole()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
            {/* divider line */}

            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK,
                    width: "100%",
                    marginTop: 7,
                    height: 2
                }}
            />

            {/* Add new Usere dialog */}
            {/* <Box> */}
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                display: "block",
                borderColor: 'primary.light',
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} gutterBottom>{t("YC_ADD_NEW_USER")}</Typography>

                    <Typography variant="caption" >{t("YC_CREATE_NEW_USER_THIS_APPLICATION")}</Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption"> {t("YC_USERNAME_REQUIRED")}  </Typography>
                    <Box sx={{
                        marginLeft: "17%",
                        width: "100%",
                        boxShadow: 4
                     }}>
                        <TextField style={{
                            width: "100%",
                            fontStyle: "italic"
                        }} id="username" label={t("YC_USE_LETTERS_WITHOUT_SPACE")} variant="outlined"
                            size="small" 
                            value={inputData.userName}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    userName: trimStr,
                                }));
                            }}/>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_NAME_REQUIRED")}</Typography>
                    
                            <TextField style={{
                            fontStyle: "italic",
                            marginRight:"-80px",
                        }}
                        sx={{ boxShadow: 4 }}
                            id="mobile" label={t("YC_FIRST_NAME")} variant="outlined" size="small"
                            value={inputData.firstName}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    firstName: trimStr,
                                }));
                            }} />

                             <TextField style={{
                            fontStyle: "italic",
                        }}
                        sx={{ boxShadow: 4 }}
                            id="mobile" label={t("YC_LAST_NAME")} variant="outlined" size="small" 
                            value={inputData.lastName}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    lastName: trimStr,
                                }));
                            }}
                            />
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_EMAIL_REQUIRED")}</Typography>
                    <Box sx={{ marginLeft: "20%",
                    width: "100%", boxShadow: 4}}>
                        <TextField style={{
                            width: "100%",
                            fontStyle: "italic",
                        }}
                            id="email" label={t("YC_OFFICIAL_MAIL_ID")} variant="outlined" size="small"
                            value={inputData.email} 
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                const validEmail = validateEmail(trimStr);
                                setIsValidEmail({value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : ""})
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    email: trimStr,
                                }));
                            }}/>
                            {(inputData.email !== "" && !isValidEmail.value) && (<div style = {{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_MOBILE_NO_REQUIRED")}</Typography>
                    <Box sx={{ marginLeft: "17%",
                    width: "100%", boxShadow: 4 }}>
                        <Box sx={{ width: { xs: "70%", sm: "71%", lg: '71%', xl: '72%' }}}>
                    <PhoneInput
                            country={"ma"}
                            value={inputData.displayMobileNo}
                            containerStyle={{marginLeft: 5, marginTop:3,  border:'none', height:30, background:'#F9F9FA'}}
                            buttonStyle={{background:'#F9F9FA', border:'none'}}
                            inputStyle={{border: 'none', width: "80%", height:30, background: '#F9F9FA', fontSize: 12, color: BLACK}}
                            onChange={(phone: string, country: any) => {
                            const reducedPhone = phone.replace(
                                country.dialCode,'',);
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    displayMobileNo: phone,
                                    mobile: reducedPhone,
                                    code: country.dialCode
                                }))
                            }} 
                    /> 
                    </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"

                }}>
                    <Typography variant="caption"> {t("YC_ROLE")}   </Typography>
                    <Box sx={{ marginRight: "0.5%", height: 40, boxShadow: 4 }}>
                        <Select
                            sx={{ height: 40 }}
                            value={newUserRole}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => {
                                const selectedRole: any = props.roleNameList.filter((role: any) => (role === event.target.value))
                                props.roleList.map((roleData: any) => {
                                    if (roleData.doc === selectedRole[0]) {
                                        setNewUserRole(roleData.doc)
                                    }
                                })
                            }}
                            placeholder={t("YC_ADMINISTRATOR")}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>{t("YC_ADMINISTRATOR")}</>;
                                }
                                return selected;
                            }}
                        >
                            {props.roleNameList.map((item: any) => {
                            return (
                                <MenuItem
                                value={item}
                                >
                                {item}
                                </MenuItem>
                            );
                            })}
                        </Select>
                    </Box>
                </Box>
                <Box sx={{ float: "right" }}>
                    <CustomButton
                        placeHolder={t("YC_ADD_USER")}
                        textTransform="none"
                        background={BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => addNewUser()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
            <Box sx={{ float: "right", width: "100%"}}>
                <CustomButton
                    placeHolder={t("YC_CANCEL")}
                    textTransform="none"
                    background={DARKGREY}
                    height="25px"
                    alignItems="center"
                    marginRight="10px"
                    marginLeft="20px"
                    width="auto"
                    borderRadius="0px"
                    buttonStyle={{
                        SUBHEADER1,
                        float: "right"
                        , marginBottom: 3
                    }}
                    onClick={() => props.setIsShowModal(false)}>
                </CustomButton>
            </Box>
        </Box>
    );
}

export default AddUserDialog;
