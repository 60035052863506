import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import CustomSelect from "components/custom-select/custom-select";
import { BLACK, BLUE, GREY, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SuccessIcon from "../../../assets/success.png";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
// import { UC_PRICING_CURRENCY, YC_ACCOUNTING_SETUP_POP_UP_TEXT, YC_ACCOUNTING_STEP_ONE_TITLE, YC_ARE_YOU_VAT_REGISTERED, YC_BUSINESS_SETUP_STEP_ONE_INFO_1, YC_BUSINESS_SETUP_STEP_ONE_INFO_2, YC_BUSINESS_SETUP_STEP_ONE_TITLE, YC_COMPANY_NAME, YC_ENTER_VAT_NUMBER, YC_LEGAL_STATUS_QUESTION, YC_NEXT_STEP, YC_NO, YC_POPUP_CONTENT, YC_REGISTRATION_NUMBER, YC_SKIP_STEP, YC_SKIP_STEP_BUTTON, YC_STEP_ACCOUNTING_SETUP_INFO_1, YC_TRADE_NAME, YC_VAT_NUMBER, YC_YES } from "utils/strings";
import PopUpModal from "components/pop-up-view/popup-modal";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(() => ({
    titleText: {
        color: BLACK,
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 1,
    },
}));


function AccountingProcessOne(props: any) {
    const { titleText } = useStyles();
    const [inputData, setInputData] = React.useState({
        vatRegistered: "",
        vatNumber: "",
        pricingCurrency: "",
    });
    const [isVatRegistered, setIsVatRegistered] = React.useState("");
    const [isNextStepDisable, setIsNextStepDisable] = React.useState(true)
    const [isOpen, setIsOpen] = React.useState(false)

    const { t } = useTranslation()

    useEffect (() => {
        getAccountingData()
    }, [])

    const getUserId = () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
          const details = JSON.parse(loginUserDetails)
          userId = details.id
        }
        return userId
      }
    
      const getAccountingData = async () => {
        const response = await HomeApi.getAccountingSetupData(getUserId());
        if (response.status === true) {
            setInputData({
                vatRegistered: response.data.vatregisterd,
                vatNumber: response.data.vatregistrationnumber,
                pricingCurrency: response.data.pricing_currency,
            })
        }
      }

    useEffect(() => {
        validateForNextStep()
    }, [inputData.vatRegistered, inputData.vatNumber, inputData.pricingCurrency])


    const validateForNextStep = () => {
    if (inputData.vatRegistered || inputData.vatNumber || inputData.pricingCurrency) {
        setIsNextStepDisable(false)
    } else {
        setIsNextStepDisable(true)
    }
    }

    const postSetupStepCount = async () => {
        const payload = {
          setup_count: 4,
          step_count: 2
        };
    
        const response = await HomeApi.postSetupStepCount(payload);
      };

    const nextStep = async () => {
        localStorage.setItem("accountingSetup", "2")
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        
        const payload = {
            vatregisterd: inputData.vatRegistered,
            vatregistrationnumber: inputData.vatNumber,
            pricing_currency: inputData.pricingCurrency,
            setup_count: 4,
            step_count: 2
        };

        const response = await HomeApi.uploadAccountStepFirstStepData(payload, userId);

        if (response.status === true) {
            Notification.notifySuccess(response.message)
            emptyData();
            props.goToNext(2);
        } else {
            Notification.notifyError(response.message)
        }
    };

    const emptyData = () => {
        setInputData({
            vatRegistered: "",
            vatNumber: "",
            pricingCurrency: "",
        })

    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const renderIcon = (iconName: any) => {
        return (
            <img
                src={iconName}
                height={iconName === ErrorExclamation ? 35 : 22}
                alt={"icon"}
            />
        );
    };


    const textFieldView = (key: string, label: string = "", value: string) => {
        return (
            <Box sx={{ width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" } }}>
                <FormControl
                    sx={{
                        mb: 1,
                        width: "100%",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        background: WHITE,
                    }}
                    variant="outlined"
                >
                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                    <OutlinedInput
                        endAdornment={
                            value?.length === 0 ? (<InputAdornment position="end">
                                {renderIcon(ErrorExclamation)}
                            </InputAdornment>) : (<InputAdornment position="end">
                                {renderIcon(SuccessIcon)}
                            </InputAdornment>)
                        }
                        label={label}
                        value={value}
                        disabled={false}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart(); if (key === "vat_number") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    vatNumber: trimStr,
                                }));
                            }
                        }}
                    />
                </FormControl>
            </Box>
        );
    };


    const dummyView = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 20 }} />;
    };


    const selectionView = (
        key: any,
        text: any,
        items: any,
        value: any,
        canSelect = true
    ) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: "110%", sm: "89%", lg: "89%", xl: "89%" }
                }}
            >
                <CustomTitleSelect
                    title={text}
                    value={value}
                    onChange={(event: any) => {
                        if (key === "vat_registered") {
                            setIsVatRegistered(event.target.value)
                            setInputData((prevFields) => ({
                                ...prevFields,
                                vatRegistered: event.target.value.toLowerCase(),
                            }))
                        } else if (key === "pricing_Currency") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                pricingCurrency: event.target.value,
                            }))
                        }
                    }}
                    items={items}
                    canSelect={canSelect}
                    keepTextGreen={true}
                />
            </Box>
        );
    };


    return (
        <Box
            sx={{
                m: 3,
                pr: 5,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
                    {t("YC_ACCOUNTING_STEP_ONE_TITLE")}
                </Typography>
                <Box sx={{ display: "flex", minWidth: { xs: 80, sm: 70, lg: 120, xl: 125 }, height: 10 }} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "flex-end",
                        width: "60%"
                    }}
                >
                    <CustomButton
                        placeHolder={t("YC_SKIP_STEP_BUTTON")}
                        textTransform="none"
                        background={GREY}
                        height="40px"
                        width={"30%"}
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2,
                            minWidth: "200px",
                        }}
                        onClick={togglePopup}
                    />
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Box
                    sx={{
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "column",
                        width: "100%"
                    }}
                >
                    <Box sx={{ display: "flex", minWidth: 80, height: 15 }} />
                    {selectionView(
                        "vat_registered",
                        t("YC_ARE_YOU_VAT_REGISTERED"),
                        ["Yes", "No"],
                        inputData.vatRegistered,
                        true
                    )}
                    {dummyView()}
                    {/* {isVatRegistered === "Yes" && textFieldView( */}
                    {textFieldView(
                        "vat_number",
                        t("YC_ENTER_VAT_NUMBER"),
                        inputData.vatNumber
                    )}
                    {dummyView()}
                    {selectionView(
                        "pricing_Currency",
                        t("UC_PRICING_CURRENCY"),
                        ["USD", "MUR", "UR", "INR"],
                        inputData.pricingCurrency,
                        true
                    )}
                </Box>
                <StepInformation
                    boxStyle={{ marginTop: "10px" }}
                    width={"95%"}
                    minWidth={"43%"}
                    maxWidth={"47%"}
                    bgColor={"rgba(15, 92, 208, 0.8)"}
                    textColor={WHITE}
                    textOne={t("YC_STEP_ACCOUNTING_SETUP_INFO_1")}
                />
            </Box>
            <div style={{ marginTop: 10 }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3
                }}
            >
                <CustomButton
                    placeHolder={`${"<" + " " + t("YC_PREVIOUS_STEP")}`}
                    textTransform="none"
                    background={GREY}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        localStorage.setItem("yellowPageStep", "3")
                        props.goToNext("yellow_page_setup", true)
                    }}
                />
                <CustomButton
                    disabled={isNextStepDisable}
                    placeHolder={t("YC_NEXT_STEP")}
                    textTransform="none"
                    background={isNextStepDisable ? GREY : BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep()
                    }}
                />
            </Box>
            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3,
                }}
            >
                <CustomButton
                    disabled={isNextStepDisable}
                    placeHolder={t("YC_NEXT_STEP")}
                    textTransform="none"
                    background={isNextStepDisable ? GREY : BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep()
                    }}
                />
            </Box> */}
            {isOpen && <PopUpModal
                title={t("YC_SKIP_STEP")}
                description={t("YC_ACCOUNTING_SETUP_POP_UP_TEXT")}
                handleClose={togglePopup}
                firstbuttonName={t("YC_YES")}
                secondbuttonName={t("YC_NO")}
                firstbuttonAction={() => {
                    postSetupStepCount()
                    localStorage.setItem("accountingSetup", "2")
                    props.goToNext(2);
                }}
                secondbuttonAction={() => {
                    togglePopup()
                }}
            />}
        </Box>
    );
}
export default AccountingProcessOne;  