import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import CustomSelect from "components/custom-select/custom-select";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import { countries } from "utils/countries";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SuccessIcon from "../../../assets/success.png";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
// import { YC_EMAIL_VALID, YC_MAIL_SENT_SUCCESSFULLY, YC_PHONE_VALID, YC_SUCCESSFULL, YC_LANDLINE_VALID, YC_OK, 
//   YC_CONTROLLER_SETUP_2_INFO_1, YC_CONTROLLER_SETUP_2_INFO_2, YC_NON_CONTROLLER_SETUP_2_INFO_1, 
//   YC_NON_CONTROLLER_SETUP_2_INFO_AFTER_DATA_FIELD, YC_CONTROLLER_SETUP_2_TITLE, YC_CONTROLLER_SETUP_2_ADD_CONTROLLER_TITLE, 
//   YC_USE_SAME_DETAILS, YC_FIRST_NAME, YC_LAST_NAME, YC_COUNTRY_CODE, YC_MOBILE_NUMBER, YC_EMAIL_OF_CONTROLLER, 
//   YC_FIXED_LINE_NUMBER, YC_POSITION_OF_CONTROLLER, YC_DIRECTOR, YC_EXECUTIVE, YC_MANAGER, YC_NEXT_STEP } from "utils/strings";
import { validateEmail, validateFaxNumber, validateMobilePhone } from "utils/helper";
import Notification from "views/Notifications/Notification";
import PopUpModal from "components/pop-up-view/popup-modal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
}));

function ControllerProcessTwo(props: any) {
  const { titleText } = useStyles();
  const [inputData, setInputData] = React.useState({
    title: "",
    firstName: "",
    lastName: "",
    code: "",
    mobilePhone: "",
    fixLoc: "",
    email: "",
    position: "",
    usedSameDetail: "Yes",
    controller: "No"
  });
  const [isValidData, setIsValidData] = React.useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState({value: true, error: ""})
  const [isValidLineNumber, setIsValidLinNumber] = React.useState({value: true, error: ""})
  const [isValidEmail, setIsValidEmail] = React.useState({value: true, error: ""})
  const [isUserAddedUser, setIsUserAddedUser] = React.useState(false);
  const [isMailSentSuccessfully, setIsMailSentSuccessfully] = React.useState(false);
  const [controllerPositionList, setControllerPositionList] = React.useState([])
  const [controllerPositionNameList, setControllerPositionNameList] = React.useState([])
  const [positionId, setPositionId] = React.useState(0)

  const {t} = useTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    // setInputData((prevFields) => ({
    //   ...prevFields,
    //   usedSameDetail: event.target.value,
    // }));
    setIsValidPhoneNumber({value: true, error: ""})
    setIsValidEmail({value: true, error: ""})
    if (event.target.value === "Yes") {
      getUserDetails(event.target.value)
    } else {
      emptyData(event.target.value)
    }
  };

  const getPositionOfController = async () => {
    const response = await HomeApi.getPositionOfController();
    if (response.status === true) {
        setControllerPositionList(response.data)
        let tempArray: any = [];
        response.data.map((positionData: any) => {
        tempArray.push(positionData.position)
        });
        setControllerPositionNameList(tempArray)
    }
}

  useEffect(() => {
    getPositionOfController()
    getControllerSetupData()
  }, []);

  const isValid = () => {
    if (inputData.firstName === "" || inputData.lastName === "" || inputData.title === "" || inputData.code === "" || inputData.email === "") {
      setIsValidData(false);
      return false;
    } else {
      if (inputData.usedSameDetail === "Yes") {
        if (isValidPhoneNumber.value){
           setIsValidData(true);
        } else {
          setIsValidData(false);
        }
      } else {
        if (isValidEmail.value && isValidPhoneNumber.value){
         setIsValidData(true);
        } else {
         setIsValidData(false);
        }
      }
    }
  };

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const loginUserData = (userDetails: any, sameDetails: string, getControllerValue: string) => {
    setInputData({
      title: userDetails.title,
      firstName: userDetails.name,
      lastName: userDetails.lname,
      code: userDetails.contrycode,
      mobilePhone: userDetails.mobile,
      fixLoc: userDetails.fixlinenumber,
      email: userDetails.email,
      position: userDetails.positioncontroller,
      usedSameDetail: sameDetails,
      controller: getControllerValue
    })

    if(userDetails.mobile){setIsValidPhoneNumber({value: true, error: ""})}
    if(userDetails.email){setIsValidEmail({value: true, error: ""})}
}

const getUserDetails = (sameDetails: string) => {
  HomeApi.getUserControllerDetails().then((res:any) => {
    if (res.status === true) {
      props.setAdditionalUserCount(Number(res.data.additiona_user))
      localStorage.setItem("user_Details", JSON.stringify(res.data))
      loginUserData(res.data, sameDetails, props.isUserController)
      isValid()
    }
  }).catch((err:any) => {});
}

const updateControllerDetails = async (userDetails: any) => {
  localStorage.setItem("controllerStep", "3")
  const payload = {
    detail_same: props.isUserController === "No" ? inputData.usedSameDetail : 'Yes',
    title: userDetails.title,
    fnamecontroller: userDetails.firstName,
    lnameofcontroller: userDetails.lastName,
    contrycode: userDetails.code,
    mobile: userDetails.mobilePhone,
    fixlinenumber: userDetails.fixLoc,
    email: userDetails.email,
    positioncontroller: positionId,
    controller: 'Yes',
    sign_up_url: "https://y-click.io/signup",
    setup_count: 1,
    step_count: 3
  };
  const response = await HomeApi.postUserControllerDetails(payload);
  if (response.status === true) {
    localStorage.setItem("user_Details", JSON.stringify(response.data))
    props.setAdditionalUserCount(Number(response.data.additiona_user))
    if(inputData.usedSameDetail === 'Yes') {
      togglePopup()
      props.setIsUserController('Yes')
    } else {
      setIsMailSentSuccessfully(true)
    }
    // Notification.notifySuccess(response.message)
  } else {
    Notification.notifyError(response.message)
  }

}; 

const togglePopup = () => {
  let details;
  const loginUserDetails = localStorage.getItem("user_Details")
  if (loginUserDetails){
     details = JSON.parse(loginUserDetails)
  }
  setIsMailSentSuccessfully(false)
  const additionalUserCount = Number(details.additiona_user)
  // if(additionalUserCount >= 2){
  //   props.setStepNumber(5)
  //   props.goToNext(5)
  // } else {
  //   props.setStepNumber(3)
  //   props.goToNext(3)
  // }
  props.setStepNumber(3)
  props.goToNext(3)
}
useEffect(() => {
  getUserDetails(inputData.usedSameDetail)
  const getUserDataFromLocalStorage = localStorage.getItem("user_Details")
  if (getUserDataFromLocalStorage) {
    const data = JSON.parse(getUserDataFromLocalStorage)
    if(data.additiona_user === "2") {
      setIsUserAddedUser(true)
    }
  } 
}, []);

useEffect(() => {
  isValid()
}, [isValidPhoneNumber, isValidEmail, inputData.firstName, inputData.lastName, inputData.title, inputData.email]);

useEffect(() => {
  controllerPositionList.map((positionData: any) => {
  if (Number(positionId) === positionData.id){
    setInputData((prevFields) => ({
      ...prevFields,
      position: positionData.position,
    }));
  }
  })
}, [positionId, inputData.position])

const getControllerSetupData = async () => {
  const response = await HomeApi.getControllerSetupData(getUserId())
  if(response.status === true){
    setPositionId(Number(response.data.positioncontroller_id))
  }
}
const emptyData = (sameDetails: string) => {
  setInputData({
    title: "",
    firstName: "",
    lastName: "",
    code: "",
    mobilePhone: "",
    fixLoc: "",
    email: "",
    position: "",
    usedSameDetail: sameDetails,
    controller: "No"
  })
  
}

const validatePhone = (mobilePhone: string) => {
  const validMobilePhone = validateMobilePhone(mobilePhone);
  return validMobilePhone;
};

  const controllerInfoView = () => {
    return(
      <StepInformation
        boxStyle={{ marginTop: "10px" }}
        width={"95%"}
        minWidth={"43%"}
        maxWidth={"47%"}
        bgColor={"rgba(15, 92, 208, 0.8)"}
        textColor={WHITE}
        textOne={t("YC_CONTROLLER_SETUP_2_INFO_1")}
        textTwo={t("YC_CONTROLLER_SETUP_2_INFO_2")}
      />
    )
  }

  const nonControllerInfoView = () => {
    return(
      <StepInformation
      boxStyle={{ marginTop: "10px" }}
      width={"95%"}
      minWidth={"43%"}
      maxWidth={"47%"}
      bgColor={RED}
      textColor={WHITE}
      textOne={t("YC_NON_CONTROLLER_SETUP_2_INFO_1")}
    />
    )
  }

  const nonControllerInfoViewAfterdataField = () => {
    return(
      <StepInformation
              boxStyle={{ marginTop: "10px" }}
              width={"95%"}
              minWidth={"43%"}
              maxWidth={"47%"}
              bgColor={"rgba(15, 92, 208, 0.8)"}
              textColor={WHITE}
              textOne={t("YC_NON_CONTROLLER_SETUP_2_INFO_AFTER_DATA_FIELD")}
            /> 
    )
  }

  const renderIcon = (iconName: any) => {
    return (
        <img
          // style={{opacity: 0.4}}
          src={iconName}
          height={iconName === ErrorExclamation ? 35 : 22}
          alt={"icon"}
        />
    );
  };

  const textFieldView = (key: string, label: string = "", value: string) => {
    return (
      <Box>
        <FormControl
          sx={{
            m: 1,
            width: key === "mobile_phone" || key === "fix_loc" ? { xs: "93%", sm: "100%", lg: "100%", xl: "100%" } : "92%",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            background: WHITE
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            endAdornment={
              value?.length === 0 ?  (<InputAdornment position="end">
              {renderIcon(ErrorExclamation)}
            </InputAdornment>) : value?.length > 1 && ((isValidPhoneNumber.error && key === "mobile_phone") ||
              (isValidEmail.error && key === "email_controller") || 
              (isValidLineNumber.error && key === "fix_loc")) ?
              <InputAdornment position="end">
                {renderIcon(ErrorExclamation)}
              </InputAdornment> :
              <InputAdornment position="end">
              {renderIcon(SuccessIcon)}
            </InputAdornment>
            }
            label={label}
            value={value}
            disabled={key === "email_controller" && inputData.usedSameDetail === 'Yes' ? true : false}
            onChange={(text) => {
              const trimStr = text.target.value.trimStart();
              if (key === "first_name") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  firstName: trimStr,
                }));
              } else if (key === "last_name") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  lastName: trimStr,
                }));
              } else if (key === "fix_loc") {
                const fax = text.target.value.replace(/(\d{3})(\d{2})(\d{2})/, "$1 $2 $3");
                  const isValidNumber = validateFaxNumber(fax);
                  setIsValidLinNumber({value:isValidNumber, error: !isValidNumber ? t("YC_LANDLINE_VALID") : ""})
                  setInputData((prevFields) => ({
                    ...prevFields,
                    fixLoc: trimStr,
                  }));
              } else if (key === "mobile_phone") {
                const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
                const isValidPhoneNumber = validatePhone(mob);
                setIsValidPhoneNumber({value:isValidPhoneNumber, error: !isValidPhoneNumber ? t("YC_PHONE_VALID") : ""})
                setInputData((prevFields) => ({
                  ...prevFields,
                  mobilePhone: trimStr,
                }));
              } else if (key === "email_controller") {
                const validEmail = validateEmail(value);
                setIsValidEmail({value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : ""})
                setInputData((prevFields) => ({
                  ...prevFields,
                  email: trimStr,
                }));
              }
              isValid();
            }} 
            error={(!!isValidPhoneNumber.error && key === "mobile_phone") ||
             (!!isValidEmail.error && key === "email_controller") || 
             (!!isValidLineNumber.error && key === "fix_loc")}
          />
          {!!isValidPhoneNumber.error && key === "mobile_phone" && (
            <FormHelperText error id="validNumber-error">
              {isValidPhoneNumber.error}
            </FormHelperText>
          )}
          {!!isValidEmail.error && key === "email_controller" && (
            <FormHelperText error id="validEmail-error">
              {isValidEmail.error}
            </FormHelperText>
          )}
          {!!isValidLineNumber.error && key === "fix_loc" && (
            <FormHelperText error id="validFixLoc-error">
              {isValidLineNumber.error}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        m: 3,
        pr: 5,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
        {props.isUserController === "No"
          ? t("YC_CONTROLLER_SETUP_2_ADD_CONTROLLER_TITLE")
          : t("YC_CONTROLLER_SETUP_2_TITLE")}
      </Typography>
      {props.isUserController === "No" && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            flexDirection: "row",
            ml: 16,
            mr: 3,
            mb: 1
          }}
        >
          {/* <div style={{ display: "flex", width: "50%" }} /> */}
          <Box sx={{ display: "flex", minWidth: { xs: "0%", sm: "0%", lg: "50%", xl: "50%" }, height: 20 }} />
          <Box sx={{
                  width: { xs: "100%", sm: "100%", lg: "50%", xl: "50%" }
                }}
          >
            <CustomTitleSelect
              title={t("YC_USE_SAME_DETAILS")}
              value={inputData.usedSameDetail}
              onChange={handleChange}
              items={["Yes", "No"]}
              boxStyle={{
                width: "100%",
                // minWidth: "30%",
              }}
              canSelect={true}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <CustomSelect
          label={"Title"}
          minWidth={80}
          items={["Mr", "Mrs", "Ms"]}
          value={inputData.title}
          onChange={(event: any) => {
            setInputData((prevFields) => ({
              ...prevFields,
              title: event.target.value,
            }));
          }}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            flexDirection: "column",
            width: "100%",
          }}
        >
          {textFieldView(
            "first_name",
            t("YC_FIRST_NAME"),
            inputData.firstName
          )}
          {textFieldView(
            "last_name",
            t("YC_LAST_NAME"),
            inputData.lastName
          )}
        </Box>
        {props.isUserController === "Yes" ? 
            controllerInfoView() :
            isValidData ? nonControllerInfoViewAfterdataField() : nonControllerInfoView()
         }
        
      </Box>
      <div style={{marginTop: 10}}/>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
            <CustomSelect
              label={t("YC_COUNTRY_CODE")}
              minWidth={80}
              items={countries.map((item) => {
                return item.phone;
              })}
              value={inputData.code}
              onChange={(event: any) => {
                setInputData((prevFields) => ({
                  ...prevFields,
                  code: event.target.value,
                }));
              }}
            />
                <Box sx={{ display: "flex", minWidth: { xs: 0, sm: 10, lg: 10, xl: 10 }, height: 20 }} />
            <Box
              sx={{
                display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                flexDirection: "column",
                width: "100%",
              }}
            >
              {textFieldView(
                "mobile_phone",
                t("YC_MOBILE_NUMBER"),
                inputData.mobilePhone
              )}
              {textFieldView("fix_loc", t("YC_FIXED_LINE_NUMBER"), inputData.fixLoc)}
            </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            flexDirection: "column",
            width: { xs: "100%", sm: "100%", lg: "95%", xl: "95%" },
          }}
        >
          {textFieldView(
            "email_controller",
            t("YC_EMAIL_OF_CONTROLLER"),
            inputData.email
          )}

          <CustomSelect
            label={t("YC_POSITION_OF_CONTROLLER")}
            minWidth={"92%"}
            items={controllerPositionNameList}
            value={inputData.position}
            isShowTick={true}
            onChange={(event: any) => {
              const selectedPosition: any = controllerPositionNameList.filter((name: any) => (name === event.target.value))
              controllerPositionList.map((positionData: any) => {
                if (positionData.position === selectedPosition[0]) {
                  setPositionId(positionData.id)
                }
              })
              setInputData((prevFields) => ({
                ...prevFields,
                position: event.target.value,
              }));
            }}
          />
          {/* {textFieldView(
            "position_controller",
            "Position of Controller",
            inputData.position
          )} */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("controllerStep", "1")
            props.setStepNumber(1)
            props.goToNext(1, "", "", true)
          }}
        />
        <CustomButton
          disabled={!isValidData}
          placeHolder={t("YC_NEXT_STEP")}
          textTransform="none"
          background={isValidData ? BLUE : GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            updateControllerDetails(inputData)
          }}
        />
      </Box>
      {isMailSentSuccessfully && <PopUpModal
        title={t("YC_SUCCESSFULL")}
        description={t("YC_MAIL_SENT_SUCCESSFULLY")}
        handleClose={togglePopup}
        firstbuttonName={t("YC_OK")}
        isHideSecondButton={true}
        isHideQuestion={true}
        firstbuttonAction={togglePopup}
      />}
    </Box>
  );
}
export default ControllerProcessTwo;
