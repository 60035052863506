import { Box, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { BLACK, BLUE, DARK_GRAY } from "utils/colors";
import { HEADER2, SUBHEADER1 } from "utils/fonts";
import CapacityDialog from "./capacity-dialog";
import ManageRoleDialog from "./manage-role-dialog";
import { t } from "i18next";

const ManageRoleStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-around",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "20px",
        width: '35%',
        background: "#FFFFFF",
    },

}))
function ManageRole(props: any) {
    const { boxView } = ManageRoleStyle();
    const [isCapacityDialog, setIsCapacityDialog] = React.useState(false);
    const [isManageRoleDialog, setManageRoleDialog] = React.useState(false);
    const [isCapacityManageDisable, setIsCapacityManageDisable] = React.useState(true);
    const [isManageRoleDisable, setIsManageRoleDisable] = React.useState(true);
    const [isUserAvailable, setIsUserAvailable] = React.useState(props.isUserAvailable);

    function handlManageRoleOpen() {
        setManageRoleDialog(true);
    }

    function handleManageRoleClose() {
        setManageRoleDialog(false);
    }
    function handlCapaityOpen() {
        setIsCapacityDialog(true);
    }

    function handleCapacityClose() {
        setIsCapacityDialog(false);
    }

    const dummyView = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 10 }} />;
    };

    useEffect(() => {
        if(!props.isUserAvailable){
            setIsManageRoleDisable(true)
            setIsCapacityManageDisable(true)
        } else {
            setIsManageRoleDisable(false)
            setIsCapacityManageDisable(false)
        }
    }, [])

    return (
        <Box sx={{ ml: 3, display: "flex",
            flexDirection: "column",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
            justifyContent: "flex-around",
            alignItems: "flex-start",
            borderRadius: "7.2px",
            marginLeft: "20px",
            width: {xs: "95%", sm: "95%", lg: "45%", xl: "35%"},
            background: "#FFFFFF",
         }}>
            <Box sx={{ ml: 3, mt: 2, mb: 4, width: '90%'}}>
                <label style={HEADER2}>{t("YC_MANAGE_ROLE_AND_CAPACITIES")}</label>
                {dummyView()}
                <label >{t("YC_SET_ROLE_CAPACITY_TO_APPLY")} </label>
                {dummyView()}
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: '100%' }}>
                    <CustomButton
                        disabled={isManageRoleDisable}
                        placeHolder={t("YC_MANAGE_ROLE")}
                        textTransform="none"
                        background={isManageRoleDisable ? DARK_GRAY : BLUE}
                        height="30px"
                        marginRight="10px"
                        width="auto"
                        borderRadius="4px"
                        onClick={handlManageRoleOpen}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <Modal
                        open={isManageRoleDialog}
                        onClose={handleManageRoleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div>
                            <ManageRoleDialog />
                        </div>
                    </Modal>

                    <CustomButton
                        disabled={false}
                        placeHolder={t("YC_MANAGE_CAPACITY")}
                        textTransform="none"
                        background={DARK_GRAY}
                        height="30px"
                        marginRight="10px"
                        width="auto"
                        borderRadius="4px"
                        onClick={handlCapaityOpen}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <Modal
                        open={isCapacityDialog}
                        onClose={handleCapacityClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div>
                            <CapacityDialog />
                        </div>
                    </Modal>
                </Box>
            </Box>
        </Box>
    );
}

export default ManageRole;
